import { useGetAccount, useGetCareprovider } from "apollo/hooks/queries";
import {
  ACCOUNT_TYPE_STAFF,
  QUERY_PROGRESS_PENDING,
  TRACK_EVENTS,
} from "core/consts";
import { CareproviderRoles } from "core/types";
import {
  SpinnerPage,
  SpinnerPageContainer,
} from "ds_legacy/components/Spinner";
import { CareproviderLoginPage } from "dsl/ecosystems/CareproviderAuthPage/CareproviderLoginPage";
import { useTracking } from "react-tracking";
import {
  useAppDispatch,
  useAppSelector,
  useJwtTokenData,
  useLoggedCareproviderId,
} from "reduxentities/hooks";
import { selectCareproviderRoles } from "reduxentities/selectors";
import { CAREPROVIDER_CHANGED } from "reduxentities/slices/authSlice";

function GetProviderAndRedirect({
  careproviderId,
  noParams = false,
  RedirectTo,
  useDefault = false,
}: {
  RedirectTo: ToType;
  careproviderId: number;
  noParams?: boolean;
  useDefault?: boolean;
}) {
  const { trackEvent } = useTracking();
  const [queryProgress, careprovider] = useGetCareprovider({
    id: careproviderId,
  });

  if (queryProgress === QUERY_PROGRESS_PENDING) {
    return <SpinnerPage id="careprovider-auth-get-careprovider-jwt" />;
  }

  // if there is an error retrieving the careprovider
  // since the id was validated before we can just
  // redirect to the login page instead of showing
  // an error
  if (!careprovider) {
    trackEvent({ name: TRACK_EVENTS.CAREPROVIDER_AUTH_JWT });
    return <CareproviderLoginPage careproviderToLog={careproviderId} />;
  }
  return (
    <RedirectTo
      careproviderId={careproviderId}
      useDefault={useDefault}
      noParams={noParams}
    />
  );
}

export default function CareproviderAuthPage({
  careproviderId: careproviderToLog,
  RedirectTo,
}: {
  RedirectTo: ToType;
  careproviderId: number | undefined;
}) {
  const careproviderId = useLoggedCareproviderId();
  const { accountId, token } = useJwtTokenData();
  const roles = useAppSelector(selectCareproviderRoles);
  const dispatch = useAppDispatch();
  const [queryProgress, account] = useGetAccount(accountId, {
    fetchPolicy: "network-only",
  });

  const careproviderChanged = (careproviderId: number) => {
    dispatch(CAREPROVIDER_CHANGED({ careproviderId }));
  };

  if (queryProgress === QUERY_PROGRESS_PENDING) {
    return (
      <SpinnerPageContainer>
        <SpinnerPage id="careprovider-auth-page" />
      </SpinnerPageContainer>
    );
  }

  if (account?.account_type === ACCOUNT_TYPE_STAFF) {
    const idForStaff =
      careproviderToLog ||
      (roles && roles.length > 0 && roles[0].careprovider?.id) ||
      1;

    if (idForStaff !== careproviderId) careproviderChanged(idForStaff);

    return (
      <GetProviderAndRedirect
        careproviderId={idForStaff}
        RedirectTo={RedirectTo}
      />
    );
  }

  // if there is no careproviderId, token or roles
  // in the redux store, redirect to the login page
  if (!careproviderId || !token || !roles)
    return <CareproviderLoginPage careproviderToLog={careproviderToLog} />;

  // if there is no careproviderId specified in the url
  // and there is a careprovider in the redux store
  // just use that one
  if (careproviderId && !careproviderToLog)
    return (
      <GetProviderAndRedirect
        careproviderId={careproviderId}
        RedirectTo={RedirectTo}
      />
    );

  // if there is a careproviderId specified in the url
  // check if it exists in the roles of the logged
  // account. if there is and if it matches the logged
  // careprovider in the redux store, just use that one
  // if the id is different, fire a token change with
  // the new id supplied
  if (careproviderToLog && roles) {
    const careproviderIsInRoles = roles.some(
      (role: CareproviderRoles) =>
        role.careprovider && role.careprovider.id == careproviderToLog,
    );
    if (careproviderIsInRoles) {
      if (careproviderToLog !== careproviderId)
        careproviderChanged(careproviderToLog);

      return (
        <GetProviderAndRedirect
          careproviderId={careproviderToLog}
          RedirectTo={RedirectTo}
        />
      );
    }
  }

  // default
  return (
    <GetProviderAndRedirect
      careproviderId={careproviderId}
      RedirectTo={RedirectTo}
      useDefault
    />
  );
}
