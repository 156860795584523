import { Auction, GetOntologyType } from "core/types";
import { Card } from "ds_legacy/components/InfoCard/index";
import { Divider, ResponsiveLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { Body, Subheading } from "ds_legacy/materials/typography";
import Translations from "translations/types";
import {
  BooleanField,
  CategoryContainer,
  OntologyField,
  StringField,
} from "../organisms/PatientProfileDetailsShared/shared";

function AccompanyingPersonPrint({
  auction,
  forCareprovider,
  translations,
}: {
  auction: Auction;
  forCareprovider?: boolean;
  translations: Translations;
}) {
  const data = auction?.patient?.transport;
  if (!data) return null;

  const { accompanying_person, require_accompanying_person } = data;
  if (!forCareprovider && !accompanying_person)
    return (
      <BooleanField
        value={require_accompanying_person}
        label={translations.patient.transport.accompanyingPersonNecessary}
      />
    );
  if (accompanying_person?.decrypted)
    return (
      <Body>{`${translations.patient.transport.accompanyingPersonNecessary}: ${accompanying_person?.decrypted}`}</Body>
    );
  return null;
}

export function StaticTransportInfoCard({
  auction,
  forCareprovider = false,
  getOntology,
  translations,
}: {
  auction: Auction;
  forCareprovider?: boolean;
  getOntology: GetOntologyType;
  translations: Translations;
}) {
  const data = auction.patient.transport;

  if (!data) return null;

  const {
    daily_transport_needed,
    require_accompanying_person,
    transport_organiser,
    transport_type,
  } = data;

  if (
    !transport_type &&
    !transport_organiser &&
    !daily_transport_needed &&
    !require_accompanying_person
  ) {
    return null;
  }

  if (forCareprovider) {
    return (
      <>
        <Divider />
        <Subheading bold>
          {translations.patient.transport.typeOfTransport}
        </Subheading>
        <ResponsiveLayout>
          <CategoryContainer>
            <OntologyField
              getOntology={getOntology}
              type="transportType"
              value={transport_type}
              verticalLayout
            />
          </CategoryContainer>
          <CategoryContainer>
            <BooleanField
              value={daily_transport_needed}
              label={translations.patient.transport.dailyTransportNeeded}
            />
          </CategoryContainer>
        </ResponsiveLayout>
        <ResponsiveLayout>
          <CategoryContainer>
            <AccompanyingPersonPrint
              forCareprovider={forCareprovider}
              translations={translations}
              auction={auction}
            />
          </CategoryContainer>
          <CategoryContainer>
            {transport_organiser && (
              <StringField
                prefix={translations.patient.transport.organizedBy.title}
                value={transport_organiser}
                testId="transport_organiser"
              />
            )}
          </CategoryContainer>
        </ResponsiveLayout>
      </>
    );
  }

  return (
    <Card noBorder width="100%">
      <Subheading bold>{translations.patient.transport.stepTitle}</Subheading>

      <OntologyField
        getOntology={getOntology}
        type="transportType"
        value={transport_type}
        verticalLayout
      />
      <BooleanField
        value={daily_transport_needed}
        label={translations.patient.transport.dailyTransportNeeded}
      />
      <AccompanyingPersonPrint
        forCareprovider={forCareprovider}
        translations={translations}
        auction={auction}
      />
      {transport_organiser && (
        <StringField
          prefix={translations.patient.transport.organizedBy.title}
          value={transport_organiser}
          testId="transport_organiser"
        />
      )}
      <Divider margin={margin(2, 0, 0, 2)} />
    </Card>
  );
}
