import { NEGATIVE_DIFF, POSITIVE_DIFF, SIMPLE_DIFF } from "core/consts";
import { Auction, PatientDiffType } from "core/types";
import {
  NEGATIVE_DIFF_STYLE,
  POSITIVE_DIFF_STYLE,
} from "ds_legacy/materials/colors";
import isEqual from "lodash/isEqual";
import Translations from "translations/types";
import { CategoryType, FieldValue } from "./shared";

type ComputeDiffType = "array" | "boolean" | "int" | "number" | "string";

export type Filter = {
  exists: (value: Auction, forClinic: boolean) => boolean;
  notEmpty?: (value: Auction) => boolean;
  valueGetter: (value: Auction) => any;
};

export type ComponentMappingItem = {
  Component: React.ComponentType<any>;
  fullWidth?: boolean;
  key: string;
};

export const DIFF_TYPE_STRING: ComputeDiffType = "string";
export const DIFF_TYPE_BOOLEAN: ComputeDiffType = "boolean";
export const DIFF_TYPE_ARRAY: ComputeDiffType = "array";
export const DIFF_TYPE_NUMBER: ComputeDiffType = "number";

const sortAscending = (a: number, b: number) => a - b;

export function getCategories({
  auction,
  componentMapping,
  filters,
  forClinic,
  getWhitelist,
  oldAuction,
}: {
  auction: Auction;
  componentMapping: ComponentMappingItem[];
  filters: Record<string, Filter>;
  forClinic: boolean;
  getWhitelist?: (params: {
    formInputValue: Auction;
  }) => Record<string, boolean>;
  oldAuction: Auction | undefined;
}): CategoryType[] {
  let filteredMapping = componentMapping;

  if (getWhitelist) {
    const whitelist = getWhitelist({ formInputValue: auction });
    const oldWhitelist = oldAuction
      ? getWhitelist({ formInputValue: oldAuction })
      : {};

    filteredMapping = componentMapping.filter(({ key }) => {
      const display = whitelist[key];
      if (display == null) {
        console.error("Missing whitelist for component", key);
        return true;
      }
      return display === true || oldWhitelist[key] === true;
    });
  }

  return filteredMapping.map(({ Component, fullWidth, key }) => {
    const { exists, notEmpty, valueGetter } = filters[key];

    return {
      Component,
      key,
      notEmpty: (oldAuction && notEmpty?.(oldAuction)) || notEmpty?.(auction),
      exists:
        (oldAuction && exists(oldAuction, forClinic)) ||
        exists(auction, forClinic),
      valueGetter,
      fullWidth: !!fullWidth,
    };
  });
}

export function isSameValue(
  value1: FieldValue | null | undefined,
  value2: FieldValue | null | undefined,
): boolean {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    const array1 = [...value1].sort(sortAscending);
    const array2 = [...value2].sort(sortAscending);
    return isEqual(array1, array2);
  }
  return value1 === value2;
}

export function isNumberAndNotZero(value: number | undefined): boolean {
  return value != null && value !== 0;
}

export const isNotEmptyString = (value: string | null | undefined): boolean =>
  value != null && value.length > 0;

export const stringFilled = (value: string | null | undefined) => value != null;

export const descriptionCategory = (
  value: { description?: string } | null | undefined,
) => value != null && isNotEmptyString(value.description);

export function isNullValue(
  value: boolean | number | string | null | undefined,
  noEmpty?: boolean,
) {
  switch (typeof value) {
    case "string":
      return value === null || (noEmpty && value === "");
    case "boolean":
      return value === null;
    case "number":
      return !value;
    default:
      return true;
  }
}

export const getKey = (value: string, prefix: string | null | undefined) =>
  `${prefix || ""}-${value}`;

export function withDeletedString(
  v: FieldValue | null | undefined,
  v2: FieldValue | null | undefined,
): boolean {
  if (typeof v === "string" && typeof v2 === "string") {
    return v === "" && v2.length > 0;
  }
  return false;
}

export function computeChipProps(props: {
  bold?: boolean;
  disabled?: boolean;
  primary?: boolean;
  selected?: boolean;
}) {
  if (props.disabled) {
    if (props.selected) return { disabled: true, bold: true };
    return { bold: true };
  }
  return props;
}

export function computeDiff(
  value: FieldValue | null | undefined,
  oldValue: FieldValue | null | undefined,
  type: ComputeDiffType,
): {
  displayValue: FieldValue;
  style: AnyObject;
  type: PatientDiffType;
} | null {
  switch (type) {
    case DIFF_TYPE_STRING:
    case DIFF_TYPE_ARRAY:
    case DIFF_TYPE_NUMBER: {
      if (value != null && oldValue == null)
        return {
          style: POSITIVE_DIFF_STYLE,
          displayValue: value,
          type: POSITIVE_DIFF,
        };
      if (
        value != null &&
        oldValue != null &&
        !withDeletedString(value, oldValue) &&
        !isSameValue(value, oldValue)
      )
        return {
          style: POSITIVE_DIFF_STYLE,
          displayValue: value,
          type: SIMPLE_DIFF,
        };

      if (
        withDeletedString(value, oldValue) ||
        (value == null && oldValue != null)
      )
        return {
          style: NEGATIVE_DIFF_STYLE,
          displayValue: oldValue || "",
          type: NEGATIVE_DIFF,
        };

      return null;
    }
    case DIFF_TYPE_BOOLEAN: {
      if ((value != null && oldValue == null) || (value && !oldValue))
        return {
          style: POSITIVE_DIFF_STYLE,
          displayValue: value,
          type: POSITIVE_DIFF,
        };

      if ((value == null && oldValue != null) || (!value && oldValue))
        return {
          style: NEGATIVE_DIFF_STYLE,
          displayValue: oldValue,
          type: NEGATIVE_DIFF,
        };

      return null;
    }
    default:
      return null;
  }
}

export function getColonedPrefix(prefix: string, translations: Translations) {
  return `${prefix}${translations.general.colon}`;
}

export function generatePrefixedString(
  translations: Translations,
  str: string,
  prefix: string | null | undefined,
  suffix?: string | null | undefined,
) {
  const strValue =
    suffix && isNotEmptyString(suffix) ? `${str} ${suffix}` : str;
  return prefix && isNotEmptyString(prefix)
    ? `${getColonedPrefix(prefix, translations)} ${strValue}`
    : strValue;
}

// Accepts object and array of keys to check
export const inputCollectionIsNotEmpty = ({
  assertionObject,
  keysToExist,
}: {
  assertionObject?: AnyObject;
  keysToExist: Array<string>;
}): boolean => {
  if (!assertionObject || !keysToExist) return false;
  const exists = keysToExist.some((key) => {
    const chunk = assertionObject[key];
    return (
      chunk !== undefined && chunk !== null && chunk !== false && chunk !== 0
    );
  });

  return exists;
};

export const activableInputCollectionIsNotEmpty = (
  collection: AnyObject,
): boolean => {
  const values = collection && Object.values(collection);
  if (values) {
    values.pop();
  }
  return !!(
    Array.isArray(values) && values.filter((value) => value !== null).length
  );
};

export const exists = (categories: Array<any>) => {
  // eslint-disable-next-line  no-plusplus
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].exists) return true;
  }

  return false;
};

export function generateCategoryArray(
  array: Array<CategoryType>,
  chunkSize: number,
) {
  let i = 0;
  return array.reduce((chunks: Array<ToType>, current: ToType) => {
    if (current.fullWidth) {
      i = chunkSize;
      return [...chunks, [current]];
    }

    i += 1;
    if ((i - 1) % chunkSize === 0) return [...chunks, [current]];

    const lastChunk = chunks.pop() || [];
    return [...chunks, [...lastChunk, current]];
  }, []);
}
