import { useIsSealdOnly } from "containers/SealdOnlyProvider";
import { useEnvContext } from "context/EnvContext";
import {
  APP_CLINIC,
  POSITIVE_DIFF,
  PREDICAMENT_STATE_UNKNOWN,
  PREDICAMENT_STATE_YES,
  SEARCH_ACTION_EDIT_SEARCH,
  SIMPLE_DIFF,
  TRACK_EVENTS,
} from "core/consts";
import { descriptiveWhitelist, findSearchAction } from "core/model/auctions";
import { getPatientName } from "core/model/patients";
import {
  getEncryptedValue,
  getPatientSessionKey,
} from "core/model/patients/encryption";
import { convertBirthDateIn } from "core/model/utils/dates";
import { formatLocation } from "core/model/utils/location";
import { OntologyType } from "core/model/utils/ontologies";
import { renderMultiLine } from "core/model/utils/text";
import {
  Auction,
  EncryptedField as EncryptedValue,
  GetOntologyType,
  Location,
  Patient,
  PatientWhitelistDefinition,
} from "core/types";
import { Chip, PrintableChip } from "ds_legacy/components/Chip";
import { LockSecurePin } from "ds_legacy/components/Icons/SecurePin";
import InfoCard, {
  CardContentContainer,
  InfoCardLite,
} from "ds_legacy/components/InfoCard";
import RSButton from "ds_legacy/components/RSButton";
import Tooltip from "ds_legacy/components/Tooltip";
import {
  ICON_DARK,
  NEGATIVE_DIFF_STYLE,
  POSITIVE_DIFF_STYLE,
  STATUS_BADGE_MEDIUM_GREY,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  dp,
  margin,
  padding,
  sizing,
  space,
  TABS_HEIGHT,
  translate,
} from "ds_legacy/materials/metrics";
import {
  Body,
  Chip as ChipText,
  FONT_SIZE_12,
  FONT_SIZE_14,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useTailwindMedia } from "dsl/atoms/ResponsiveMedia";
import { useCareseekerNavigationHandlers } from "dsl/hooks";
import { ChevronLeftIcon, LockIcon } from "lucide-react";
import React, {
  ComponentType,
  CSSProperties,
  MouseEvent,
  useCallback,
  useMemo,
} from "react";
import { useTracking } from "react-tracking";
import { useLoggedInAccount } from "reduxentities/hooks";
import styled from "styled-components";
import { useLocale, useTranslations } from "translations";
import Translations from "translations/types";
import { usePatientInfoContext, usePatientInfoSlug } from "./index";
import {
  computeChipProps,
  computeDiff,
  DIFF_TYPE_ARRAY,
  DIFF_TYPE_BOOLEAN,
  DIFF_TYPE_NUMBER,
  DIFF_TYPE_STRING,
  generatePrefixedString,
  getColonedPrefix,
  getKey,
  isNotEmptyString,
  isNullValue,
} from "./utils";

export type EncryptedFieldProps = {
  bold?: boolean;
  hideEmpty?: boolean;
  isSealdOnly: boolean;
  longContext?: boolean;
  prefix: string;
  suffix?: string | null | undefined;
  testId: string;
  value: EncryptedValue | null | undefined;
  verticalLayout?: boolean;
  withDateString?: boolean;
  withEncryptionAccess: boolean;
};

export type EmptiableFieldProps = {
  bold?: boolean;
  category?: string;
  noEmptyValue?: boolean;
  oldValue?: string | null | undefined;
  prefix?: string | null | undefined;
  testId: string;
  title?: string;
  value: string | null | undefined;
  withDiff?: boolean;
};

export type ValueGetter = (auction: Auction | undefined) => any;

export type CategoryType = {
  Component: ComponentType<any>;
  exists: boolean;
  fullWidth?: boolean;
  key: string;
  notEmpty?: boolean;
  valueGetter: (value: Auction) => any;
};

export type ContentWrapperType = (
  content: React.ReactNode | null | undefined,
) => JSX.Element;

export type FieldValue = Array<number> | Value;
type Value = boolean | number | string;

const lockIconStyle: CSSProperties = {
  color: ICON_DARK,
  fontSize: FONT_SIZE_12,
  height: dp(12),
  width: dp(12),
  display: "inline",
  verticalAlign: "baseline",
};

export const blurStyle: CSSProperties = {
  filter: "blur(0.3rem)",
  background: "white",
  userSelect: "none",
} as const;

export const italic: CSSProperties = {
  color: STATUS_BADGE_MEDIUM_GREY,
  fontStyle: "Italic",
};

const ContentWrapper = styled.div`
  display: flex;
`;

const BoldLabel = styled.span`
  font-weight: ${FONT_WEIGHT_BOLD};
`;

const IconWrapper = styled.span`
  white-space: nowrap;
`;

const PrefixWrapper = styled.span<{ bold?: boolean }>`
  margin-right: ${dp(-12)};
  font-weight: ${(props) =>
    props.bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR};
  font-size: ${FONT_SIZE_14};
  white-space: nowrap;
`;

export const BodyWrapper = styled(Body)<{ column?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
`;

export const ChipContainer = styled.div`
  margin: ${margin(0.5)};
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media print {
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    page-break-inside: avoid;
    max-width: 100%;
  }
`;

const CutMargin = styled.span`
  margin-right: -28px;
`;

export const BoxLayout = styled.div`
  box-sizing: border-box;
  @media screen {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    max-width: ${dp(750)};
    padding-top: ${space(2)};
  }

  @media screen {
    & {
      padding-left: ${space(3)};
    }
  }

  @media screen and (max-width: 1360px) {
    & {
      padding-left: ${space(3)};
    }
  }

  @media screen and (max-width: 760px) {
    & {
      padding-left: 0px;
      width: 100vw;
    }
  }
`;

export const InfosBox = styled(BoxLayout)`
  @media screen {
    padding-bottom: ${space(15)};
    padding-right: ${space(0)};
    align-items: stretch;
    flex-direction: column;
  }
`;

export const InfosPartBox = styled.div<{ marginBottom?: number }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: ${({ marginBottom }) => margin(0, 0, marginBottom || 1, 0)};
`;

export const ServicesBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  margin: 0;
  .facility-circle {
    margin: ${0};
  }
`;

export const FieldsBox = styled.div<{
  column?: boolean;
  fit?: boolean;
  flexWrap?: boolean;
  grow?: boolean;
  justifyContent?: string;
  margin?: string | false;
  noBasis?: boolean;
  unshrink?: boolean;
  width?: string;
}>`
  display: ${(props) => (props.fit ? "inline-block" : "flex")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  flex-shrink: ${(props) => (props.unshrink ? "0" : "1")};
  flex-grow: ${(props) => (props.grow ? "1" : "0")};
  flex-basis: ${(props) => (props.noBasis ? "0px" : "auto")};
  justify-content: ${(props) => props.justifyContent};
  flex-wrap: ${(props) => (props.flexWrap ? "wrap" : "nowrap")};
  align-content: flex-start;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin || margin(0, 0, 2, 0)};
  word-break: break-word;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  height: ${TABS_HEIGHT};
  justify-content: space-between;
  padding: ${padding(0.5)};
  width: 100%;
`;

export const isWhitelisted = (
  whitelist: PatientWhitelistDefinition,
  auction: Auction | null | undefined,
  oldAuction: Auction | null | undefined,
): boolean => {
  return (
    (auction &&
      descriptiveWhitelist(whitelist)({
        formInputValue: auction,
        careseeker: auction.patient.careseeker,
      })) ||
    (oldAuction &&
      descriptiveWhitelist(whitelist)({
        formInputValue: oldAuction,
        careseeker: oldAuction.patient.careseeker,
      })) ||
    false
  );
};

export const isInfectionsAndGermsPredicamentNegative = (
  value: { infection_and_germs_state?: number } | null | undefined,
  translations: Translations,
): [boolean, string] => [
  !!value && value.infection_and_germs_state !== PREDICAMENT_STATE_YES,
  !!value && value.infection_and_germs_state === PREDICAMENT_STATE_UNKNOWN
    ? translations.general.unknown
    : translations.auctionRequest.noGermsInfections,
];

export const DisplayUserIdOrPatientName = ({
  bold,
  hyphen,
  parenthesis,
  patient,
  prefix,
  showName,
  translations,
}: {
  bold?: boolean;
  hyphen?: boolean;
  parenthesis?: boolean;
  patient: Patient | null | undefined;
  prefix: string | null | undefined;
  showName: boolean;
  translations: Translations;
}) => {
  const patientName = (showName && patient && getPatientName(patient)) || null;
  const userId = patient?.user_id;
  const fontWeight = bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR;
  const prefixStyle = { style: { fontWeight: FONT_WEIGHT_REGULAR } };
  const userIdSlug = (content: string) =>
    userId ? (parenthesis ? ` (${content})` : " " + content) : null;

  const join = hyphen ? " - " : " ";

  return patientName ? (
    <span
      style={{
        fontWeight,
        textTransform: "none",
      }}
    >
      {prefix && <span {...prefixStyle}>{`${prefix}${join}`}</span>}
      {patientName}
      {userId && <span>{` (${userId})`}</span>}
    </span>
  ) : (
    <>
      {prefix && <span {...prefixStyle}>{prefix}</span>}
      <span style={{ fontWeight }}>
        {" "}
        {userIdSlug(`${translations.patient.patient} ${userId}`)}
      </span>
    </>
  );
};

export const ActionBanner = ({
  action,
  actions,
  noBorder,
  patient,
  text,
}: {
  action: (() => void) | undefined;
  actions?: Array<React.ReactNode>;
  noBorder?: boolean;
  patient?: Patient | null | undefined;
  text: string;
}) => {
  const translations = useTranslations();
  const { app } = useEnvContext();

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (action) action();
    },
    [action],
  );

  return (
    <InfoCard cardMargin="0px 0px -1px 0px" noBorder={noBorder}>
      <CardContentContainer noPadding noBorder={noBorder}>
        <Container>
          <RSButton
            color="grey"
            id="back_to_search"
            LeftIcon={ChevronLeftIcon}
            loading="na"
            onClick={onClick}
            style={{ margin: margin(0) }}
            variant="text"
          >
            <div style={{ width: "100%" }}>
              <DisplayUserIdOrPatientName
                showName={app === APP_CLINIC}
                patient={patient}
                translations={translations}
                prefix={text}
                hyphen
                parenthesis
                bold
              />
            </div>
          </RSButton>
          <div style={{ display: "flex", alignItems: "center" }}>{actions}</div>
        </Container>
      </CardContentContainer>
    </InfoCard>
  );
};

function Prefix({
  bold,
  text,
  translations,
}: {
  bold: boolean;
  text: string | null | undefined;
  translations: Translations;
}) {
  return (
    <PrefixWrapper bold={bold}>
      {`${text}${translations.general.colon} `}
    </PrefixWrapper>
  );
}

function PrettyStringAndLockIcon({ value }: { value: string }) {
  const trimmed = value.trim();
  const lastSpace = value.lastIndexOf(" ") + 1;

  let start = trimmed,
    end = "";
  if (lastSpace > 0) {
    start = trimmed.substring(0, lastSpace);
    end = trimmed.substring(lastSpace);
  }

  return (
    <>
      {start}
      <IconWrapper>
        {end}
        <LockIcon
          style={{
            ...lockIconStyle,
            margin: margin(0, 0, 0, 2),
          }}
          size={lockIconStyle.fontSize}
        />
      </IconWrapper>
    </>
  );
}

const defaultWrapper: ContentWrapperType = (content) => <>{content}</>;

function ChipValue({
  chipProps,
  keyPrefix,
  prefix,
  style,
  suffix,
  textBreak,
  value,
}: {
  chipProps: {
    bold?: boolean;
    disabled?: boolean;
    primary?: boolean;
    selected?: boolean;
  };
  keyPrefix?: string;
  prefix?: string;
  style?: React.CSSProperties;
  suffix?: string;
  textBreak?: boolean;
  value: string;
}) {
  const print = usePrint();
  const translations = useTranslations();

  const CustomChip = print ? PrintableChip : Chip;
  const chipStyle =
    style != null
      ? {
          primary: true,
          selected: false,
          backgroundColor: style.backgroundColor,
          opacity: 0.9,
        }
      : {};
  const props = {
    ...computeChipProps(chipProps),
    ...chipStyle,
    textBreak,
  };

  return (
    <ChipContainer key={getKey(value, keyPrefix)} style={style}>
      <CustomChip {...props}>
        {style != null ? (
          <ChipText
            primary
            bold
            textBreak={textBreak}
            dark
            style={{
              textDecoration: style.textDecoration,
              margin: margin(0),
            }}
          >
            {generatePrefixedString(translations, value, prefix, suffix)}
          </ChipText>
        ) : (
          generatePrefixedString(translations, value, prefix, suffix)
        )}
      </CustomChip>
    </ChipContainer>
  );
}

export function ArrayChip({
  bold,
  disabled,
  getOntology,
  keyPrefix,
  oldValue,
  primary,
  selected,
  type,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  getOntology: GetOntologyType;
  keyPrefix: string;
  oldValue?: Array<number> | null | undefined;
  primary?: boolean;
  selected?: boolean;
  type: OntologyType;
  value: Array<number> | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_ARRAY);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const merged = [...(value || []), ...(oldValue || [])].filter(
      (v, i, arr) => arr.indexOf(v) === i,
    );

    return (
      <>
        {merged.map((v) => (
          <OntologyChip
            getOntology={getOntology}
            key={getKey(v.toString(), keyPrefix)}
            type={type}
            value={value?.includes(v) ? v : null}
            oldValue={oldValue?.includes(v) ? v : null}
            keyPrefix={keyPrefix}
            withDiff
            {...chipProps}
          />
        ))}
      </>
    );
  }

  if (!value || !Array.isArray(value)) return null;
  return (
    <>
      {value.map((key) => (
        <ChipValue
          key={getKey(key.toString(), keyPrefix)}
          value={getOntology({ type, key })}
          keyPrefix={keyPrefix}
          chipProps={chipProps}
        />
      ))}
    </>
  );
}

export function NumberChip({
  bold,
  disabled,
  keyPrefix,
  oldValue,
  prefix,
  primary,
  selected,
  transform,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  keyPrefix: string;
  oldValue?: number | null | undefined;
  prefix?: string;
  primary?: boolean;
  selected?: boolean;
  transform: (value: number) => string;
  value: number | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_NUMBER);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { displayValue, style } = diff;
    return typeof displayValue === "number" ? (
      <ChipValue
        value={transform(displayValue)}
        prefix={prefix}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
      />
    ) : null;
  }

  if (value == null) return null;
  return (
    <ChipValue
      value={transform(value)}
      prefix={prefix}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
    />
  );
}

export function OntologyChip({
  bold,
  disabled,
  getOntology,
  keyPrefix,
  oldValue,
  primary,
  selected,
  type,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  getOntology: GetOntologyType;
  keyPrefix: string;
  oldValue?: boolean | number | null | undefined;
  primary?: boolean;
  selected?: boolean;
  type: OntologyType;
  value: boolean | number | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_NUMBER);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { displayValue, style } = diff;
    return typeof displayValue === "number" ? (
      <ChipValue
        value={getOntology({ type, key: displayValue })}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
      />
    ) : null;
  }

  if (!value || typeof value !== "number") return null;
  return (
    <ChipValue
      value={getOntology({ type, key: value })}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
    />
  );
}

export function BooleanChip({
  bold,
  disabled,
  keyPrefix,
  label,
  oldValue,
  prefix,
  primary,
  selected,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  keyPrefix: string;
  label: string | { oldValue: string; value: string };
  oldValue?: boolean;
  prefix?: string;
  primary?: boolean;
  selected?: boolean;
  value: boolean;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_BOOLEAN);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { style, type } = diff;
    const valueLabel =
      typeof label === "string"
        ? label
        : type === POSITIVE_DIFF
        ? label.value
        : label.oldValue;
    return (
      <ChipValue
        value={valueLabel}
        prefix={prefix}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
      />
    );
  }

  if (!value) return null;
  const valueLabel = typeof label === "string" ? label : label.value;
  return (
    <ChipValue
      value={valueLabel}
      prefix={prefix}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
    />
  );
}

export function StringChip({
  bold,
  disabled,
  keyPrefix,
  oldValue,
  prefix,
  primary,
  selected,
  suffix,
  textBreak,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  keyPrefix: string;
  oldValue?: string | null | undefined;
  prefix?: string;
  primary?: boolean;
  selected?: boolean;
  suffix?: string;
  textBreak?: boolean;
  value: string | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_STRING);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { displayValue, style } = diff;
    return typeof displayValue === "string" ? (
      <ChipValue
        value={displayValue}
        suffix={suffix}
        prefix={prefix}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
        textBreak={textBreak}
      />
    ) : null;
  }

  if (!value) return null;
  return (
    <ChipValue
      value={value}
      prefix={prefix}
      suffix={suffix}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
      textBreak={textBreak}
    />
  );
}

function FieldTitle({
  oldValue,
  title,
  value,
  withDiff,
}: {
  oldValue: boolean;
  title: string;
  value: boolean;
  withDiff: boolean | null | undefined;
}): JSX.Element {
  if (!withDiff) return <>{title}</>;
  const diff = computeDiff(value, oldValue, DIFF_TYPE_BOOLEAN);

  return diff != null ? (
    <div style={{ display: "flex" }}>
      <span style={diff.style}>{title}</span>
    </div>
  ) : (
    <>{title}</>
  );
}

export function EmptiableField({
  bold,
  category,
  noEmptyValue,
  oldValue,
  prefix,
  testId,
  title,
  value,
  withDiff,
}: EmptiableFieldProps) {
  const translations = useTranslations();

  if (
    withDiff
      ? isNullValue(value, noEmptyValue) && isNullValue(oldValue, noEmptyValue)
      : isNullValue(value, noEmptyValue)
  )
    return null;

  return category != null ? (
    <CategoryContainer className={category} data-testid={testId}>
      {title && (
        <Subheading bold>
          <FieldTitle
            title={title}
            value={value != null}
            oldValue={oldValue != null}
            withDiff={withDiff}
          />
        </Subheading>
      )}
      <CategoryDescription
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        noEmptyValue={noEmptyValue}
        title={title}
      />
    </CategoryContainer>
  ) : (
    <HorizontalLayout flexWrap="wrap" data-testid={testId} maxWidth="100%">
      {title && (
        <CutMargin>
          <Body
            data-testid="title"
            maxWidth={sizing(45)}
            fontWeight={bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR}
          >
            <FieldTitle
              title={`${title}${translations.general.colon} `}
              value={value != null}
              oldValue={oldValue != null}
              withDiff={withDiff}
            />
          </Body>
        </CutMargin>
      )}
      <CategoryDescription
        value={value}
        prefix={prefix}
        oldValue={oldValue}
        withDiff={withDiff}
        noEmptyValue={noEmptyValue}
        title={title}
      />
    </HorizontalLayout>
  );
}

export function CategoryDescription({
  noEmptyValue,
  oldValue,
  prefix,
  title,
  value,
  withDiff,
}: {
  noEmptyValue?: boolean;
  oldValue?: string | null | undefined;
  prefix?: string | null | undefined;
  title?: string;
  value: string | null | undefined;
  withDiff?: boolean;
}) {
  if (
    withDiff
      ? isNullValue(value, noEmptyValue) && isNullValue(oldValue, noEmptyValue)
      : isNullValue(value, noEmptyValue)
  )
    return null;

  const diff = computeDiff(value, oldValue, DIFF_TYPE_STRING);
  if (withDiff && diff?.displayValue) {
    return (
      <StringField
        testId="description"
        prefix={prefix}
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
      />
    );
  }

  if (isNotEmptyString(value))
    return <StringField testId="description" prefix={prefix} value={value} />;

  return (
    <InfoText
      title={title}
      prefix={prefix}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function OntologyValue({
  getOntology,
  label,
  style,
  type,
  value,
  verticalLayout = false,
}: {
  getOntology: GetOntologyType;
  label?: string;
  style?: React.CSSProperties;
  type: OntologyType;
  value?: Array<number> | number;
  verticalLayout: boolean;
}) {
  if (!value) return null;

  if (Array.isArray(value)) {
    const values = verticalLayout
      ? value.map((key) => <div key={key}>{getOntology({ type, key })}</div>)
      : value.map((key) => getOntology({ type, key })).join(", ");
    return (
      <ContentWrapper>
        <Body style={style}>{values}</Body>
      </ContentWrapper>
    );
  }

  const wrappedContent = (
    <ContentWrapper>
      <Body style={style}>{getOntology({ type, key: value })}</Body>
    </ContentWrapper>
  );

  return label ? (
    <Category title={label} category={type}>
      {wrappedContent}
    </Category>
  ) : (
    wrappedContent
  );
}

export function OntologyField({
  label,
  value,
  oldValue,
  withDiff,
  type,
  wrap = defaultWrapper,
  verticalLayout = false,
  getOntology,
}: {
  getOntology: GetOntologyType;
  label?: string;
  oldValue?: Array<number> | number | null | undefined;
  type: OntologyType;
  value: Array<number> | number | null | undefined;
  verticalLayout?: boolean;
  withDiff?: boolean;
  wrap?: ContentWrapperType;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_NUMBER);
  if (diff) {
    const { displayValue, style, type: PatientDiffType } = diff;
    if (
      PatientDiffType === SIMPLE_DIFF &&
      value != null &&
      (typeof value === "number" || Array.isArray(value)) &&
      oldValue != null &&
      (typeof oldValue === "number" || Array.isArray(oldValue))
    ) {
      const diff = (
        <VerticalLayout>
          <OntologyValue
            getOntology={getOntology}
            value={oldValue}
            type={type}
            verticalLayout={verticalLayout}
            style={NEGATIVE_DIFF_STYLE}
          />
          <OntologyValue
            getOntology={getOntology}
            value={value}
            type={type}
            verticalLayout={verticalLayout}
            style={POSITIVE_DIFF_STYLE}
          />
        </VerticalLayout>
      );

      return wrap(
        label ? (
          <Category title={label} category={type}>
            {diff}
          </Category>
        ) : (
          diff
        ),
      );
    }

    return displayValue != null &&
      (typeof displayValue === "number" || Array.isArray(displayValue))
      ? wrap(
          <OntologyValue
            getOntology={getOntology}
            label={label}
            value={displayValue}
            type={type}
            verticalLayout={verticalLayout}
            style={style}
          />,
        )
      : null;
  }

  if (!value) return null;

  return wrap(
    <OntologyValue
      getOntology={getOntology}
      label={label}
      value={value}
      type={type}
      verticalLayout={verticalLayout}
    />,
  );
}

export function BooleanField({
  value,
  oldValue,
  withDiff,
  label,
  wrap = defaultWrapper,
}: {
  label: string;
  oldValue?: boolean | null | undefined;
  value: boolean | null | undefined;
  withDiff?: boolean;
  wrap?: ContentWrapperType;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_BOOLEAN);
  if (diff)
    return wrap(
      <ContentWrapper>
        <Body style={diff.style}>{label}</Body>
      </ContentWrapper>,
    );

  if (!value) return null;
  return wrap(<Body>{label}</Body>);
}

export function LocationContent({
  bold = true,
  location,
  oldLocation,
  prefixText,
  withDiff,
}: {
  bold?: boolean;
  location: Location | null | undefined;
  oldLocation?: Location | null | undefined;
  prefixText?: string | null | undefined;
  withDiff?: boolean;
}) {
  const translations = useTranslations();
  const encryptedData = location?.encrypted_house_number;
  const decrypted = location?.encrypted_house_number?.decrypted;
  const blurred = encryptedData && !decrypted;

  return (
    <StringField
      testId="encrypted_house_number"
      contentMargin={blurred ? margin(0, 0, 0, 2) : undefined}
      value={location && formatLocation({ location, translations })}
      oldValue={
        oldLocation && formatLocation({ location: oldLocation, translations })
      }
      withDiff={withDiff}
      wrap={(content) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: padding(0, 2),
            alignItems: "baseline",
            overflow: "hidden",
          }}
        >
          <Prefix
            bold={bold}
            text={prefixText || translations.address.address}
            translations={translations}
          />
          {content}
          {blurred && (
            <StringValue
              testId="encrypted_house_number"
              value="Blur"
              style={{ ...blurStyle, margin: margin(0, 2, 0, 0.5) }}
            />
          )}
          {(decrypted || blurred) && (
            <div style={{ width: dp(16) }}>
              <Tooltip
                title={
                  blurred
                    ? translations.auctionRequest.accessEncryptedDataHint
                    : undefined
                }
              >
                <LockIcon
                  style={{ ...lockIconStyle, transform: translate({ y: 2 }) }}
                  size={lockIconStyle.fontSize}
                />
              </Tooltip>
            </div>
          )}
        </div>
      )}
    />
  );
}

export function LocationContentSection({
  bold,
  location,
  oldLocation,
  title,

  withDiff,
}: {
  bold?: boolean;
  location: Location | null | undefined;
  oldLocation?: Location | null | undefined;
  title?: string | null | undefined;
  withDiff?: boolean;
}) {
  return (
    <LocationContent
      location={location}
      oldLocation={oldLocation}
      withDiff={withDiff}
      prefixText={title}
      bold={bold}
    />
  );
}

export function StringValue({
  multiLine,
  prefix,
  style,
  testId,
  value,
}: {
  multiLine?: boolean | null | undefined;
  prefix?: string | null | undefined;
  style?: ToType | null | undefined;
  testId: string;
  value: string;
}) {
  const translations = useTranslations();
  const content = generatePrefixedString(translations, value, prefix);

  return multiLine ? (
    <ContentWrapper style={{ flexDirection: "column" }}>
      <Body data-testid={testId} style={style}>
        {multiLine ? renderMultiLine(content) : content}
      </Body>
    </ContentWrapper>
  ) : (
    <Body
      className="ContentWrapper"
      data-testid={testId}
      style={{
        ...style,
        wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
      }}
    >
      {content}
    </Body>
  );
}

export function StringField({
  value,
  oldValue,
  withDiff,
  prefix,
  testId,
  multiLine,
  contentMargin,
  wrap = defaultWrapper,
}: {
  contentMargin?: string;
  multiLine?: boolean;
  oldValue?: string | null | undefined;
  prefix?: string | null | undefined;
  testId: string;
  value: string | null | undefined;
  withDiff?: boolean;
  wrap?: ContentWrapperType;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_STRING);
  if (diff) {
    const { displayValue, style, type } = diff;
    if (
      type === SIMPLE_DIFF &&
      typeof value === "string" &&
      typeof oldValue === "string"
    )
      return wrap(
        <VerticalLayout>
          <StringValue
            multiLine={multiLine}
            style={NEGATIVE_DIFF_STYLE}
            value={oldValue}
            prefix={prefix}
            testId={testId}
          />
          <StringValue
            multiLine={multiLine}
            style={POSITIVE_DIFF_STYLE}
            value={value}
            prefix={prefix}
            testId={testId}
          />
        </VerticalLayout>,
      );

    if (typeof displayValue !== "string") return null;
    return wrap(
      <StringValue
        multiLine={multiLine}
        style={style}
        value={displayValue}
        prefix={prefix}
        testId={testId}
      />,
    );
  }

  if (!value) return null;

  return wrap(
    <StringValue
      multiLine={multiLine}
      value={value}
      style={{ margin: contentMargin }}
      prefix={prefix}
      testId={testId}
    />,
  );
}

export function EncryptedField({
  bold = false,
  hideEmpty,
  isSealdOnly,
  longContext,
  prefix,
  suffix,
  testId,
  value,
  verticalLayout = false,
  withDateString,
  withEncryptionAccess,
}: EncryptedFieldProps) {
  const translations = useTranslations();
  const locale = useLocale();
  const decryptedValue = getEncryptedValue(value);

  if (withEncryptionAccess && !value) {
    if (hideEmpty) return null;

    return (
      <BodyWrapper>
        <HorizontalLayout aligned maxWidth="100%">
          <Prefix bold={bold} text={prefix} translations={translations} />
          <StringValue
            testId={testId}
            value={translations.actions.valueEmtpy}
            style={italic}
          />
          <LockIcon style={lockIconStyle} size={lockIconStyle.fontSize} />
        </HorizontalLayout>
      </BodyWrapper>
    );
  }

  const withEncryptedValue = isSealdOnly
    ? value?.seald_content
    : value?.content;
  if (withEncryptedValue) {
    if (value && !decryptedValue) {
      return verticalLayout ? (
        <BodyWrapper whiteSpace="nowrap" margin={margin(0, 0, 0, 2)}>
          <VerticalLayout maxWidth="100%">
            <HorizontalLayout>
              <Prefix bold={bold} text={prefix} translations={translations} />
              <LockSecurePin
                tooltip={translations.auctionRequest.accessEncryptedDataHint}
                style={{ ...lockIconStyle, margin: margin(0.4, 0, 0, 2) }}
              />
            </HorizontalLayout>
            <StringValue
              testId={testId}
              value="Blurred Data"
              style={{ ...blurStyle, margin: margin(0, 0.75) }}
            />
          </VerticalLayout>
        </BodyWrapper>
      ) : (
        <BodyWrapper>
          <HorizontalLayout aligned maxWidth="100%">
            <Prefix bold={bold} text={prefix} translations={translations} />
            <StringValue
              value="Blurred Data"
              style={blurStyle}
              testId={testId}
            />
            <Tooltip
              title={translations.auctionRequest.accessEncryptedDataHint}
            >
              <LockIcon style={lockIconStyle} size={lockIconStyle.fontSize} />
            </Tooltip>
          </HorizontalLayout>
        </BodyWrapper>
      );
    }
  }
  if (decryptedValue) {
    const prefixAndValue = generatePrefixedString(
      translations,
      decryptedValue,
      prefix,
      suffix,
    );
    if (longContext) {
      return (
        <ContentWrapper>
          <Body>
            <PrettyStringAndLockIcon value={prefixAndValue} />
          </Body>
        </ContentWrapper>
      );
    }
    const formattedValue = withDateString
      ? convertBirthDateIn(value, { locale }) || ""
      : decryptedValue;

    if (verticalLayout) {
      const colonedPrefix = getColonedPrefix(prefix, translations);

      return (
        <ContentWrapper>
          <Body>
            <BoldLabel>
              <PrettyStringAndLockIcon value={colonedPrefix} />
            </BoldLabel>
            <StringValue
              testId={testId}
              value={
                suffix ? formattedValue.concat(` ${suffix}`) : formattedValue
              }
              style={{ margin: margin(0) }}
            />
            <br />
          </Body>
        </ContentWrapper>
      );
    }
    return (
      <BodyWrapper>
        <HorizontalLayout aligned maxWidth="100%">
          <Prefix bold={bold} text={prefix} translations={translations} />
          <StringValue
            testId={testId}
            value={
              suffix ? formattedValue.concat(` ${suffix}`) : formattedValue
            }
          />
          <LockIcon style={lockIconStyle} size={lockIconStyle.fontSize} />
        </HorizontalLayout>
      </BodyWrapper>
    );
  }
  return null;
}

export function EmptiableCategory({
  category,
  children,
  empty,
  hasEmptyField,
  oldValue,
  title,
  value,
  withDiff,
}: {
  category?: string;
  children?: React.ReactNode;
  empty?: boolean;
  hasEmptyField?: boolean;
  oldValue?: AnyObject | string | null | undefined;
  title: string;
  value?: AnyObject | string | null | undefined;
  withDiff?: boolean;
}) {
  return (
    <Category
      category={category}
      title={
        <FieldTitle
          title={title}
          value={value != null}
          oldValue={oldValue != null}
          withDiff={withDiff}
        />
      }
    >
      {empty ? (
        <InfoText
          title={title}
          value={value}
          oldValue={oldValue}
          withDiff={withDiff}
        />
      ) : (
        children
      )}
      {hasEmptyField && <InfoText />}
    </Category>
  );
}

function CareseekerInfoText({
  prefix,
  title,
}: {
  prefix?: string | null;
  title: string;
}) {
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const { patient: patientNavigation } = useCareseekerNavigationHandlers();
  const { assessmentSlug, auctionId, patientId } = usePatientInfoSlug();

  return (
    <Body
      data-testid={`add-missing-${title.replace(/ /g, "-").toLowerCase()}`}
      primary
      fontWeight="bold"
      style={{ cursor: "pointer" }}
      onClick={() => {
        trackEvent({
          name: TRACK_EVENTS.MISSING_FIELD,
          page: assessmentSlug,
        });
        patientNavigation.goToassessmentSlug({
          patientId,
          auctionId,
          assessmentSlug,
        });
      }}
    >
      {prefix && (
        <Body data-testid="description" display="inline" margin={margin(0)}>
          {`${getColonedPrefix(prefix, translations)} `}
        </Body>
      )}
      {translations.auctionResponse.areYouSureNotAddInfo}
    </Body>
  );
}

function InfoText({
  oldValue,
  prefix,
  title = "",
  value,
  withDiff,
}: {
  oldValue?: AnyObject | string | null | undefined;
  prefix?: string | null | undefined;
  title?: string;
  value?: AnyObject | string | null | undefined;
  withDiff?: boolean | null | undefined;
}) {
  const translations = useTranslations();
  const { auction, isClinicApp, withEdit } = usePatientInfoContext();
  const diff = withDiff
    ? computeDiff(value != null, oldValue != null, DIFF_TYPE_BOOLEAN)
    : undefined;
  const editSearchAction = useMemo(
    () => auction && findSearchAction(auction, SEARCH_ACTION_EDIT_SEARCH),
    [auction?.search_actions],
  );

  if (!withEdit) {
    return (
      <Body>
        <div style={{ display: "flex" }}>
          <span style={diff?.style || {}}>
            {translations.auctionRequest.noAdditionalInformationProvided}
          </span>
        </div>
      </Body>
    );
  }

  if (isClinicApp && editSearchAction)
    return <CareseekerInfoText prefix={prefix} title={title} />;

  return null;
}

export function Category({
  category,
  children,
  title,
}: {
  category?: string;
  children?: React.ReactNode;
  title: React.ReactNode | string | null | undefined;
}) {
  return (
    <CategoryContainer className={category}>
      <Subheading bold>{title}</Subheading>
      {children}
    </CategoryContainer>
  );
}

export function Categories({
  auction,
  categories,
  forClinic,
  getOntology,
  oldAuction,
  translations,
}: {
  auction: Auction;
  categories: CategoryType[];
  forClinic?: boolean;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
  translations: Translations;
}) {
  const { isDesktop } = useTailwindMedia();
  const account = useLoggedInAccount();
  const isSealdOnly = useIsSealdOnly().isSealdOnly({
    oldSession: auction.patient?.session_key_context?.has_session_keys,
    newSealdSession: auction.patient?.seald_encryption_context?.seald_id,
  });

  const withEncryptionAccess = isSealdOnly
    ? !!auction.patient?.seald_encryption_context
    : !!getPatientSessionKey(account, auction.patient);

  const validCategories = categories.filter(
    (category) => category.exists && category.Component !== null,
  );

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: dp(0) }}>
      {validCategories.map(
        ({ Component, fullWidth, key, notEmpty, valueGetter }) => {
          const width =
            !isDesktop || fullWidth ? "100%" : `calc(50% - ${dp(0)})`;
          return (
            <div key={key} style={{ width }} data-testid={key}>
              <Component
                auction={auction}
                empty={!notEmpty}
                forClinic={forClinic}
                getOntology={getOntology}
                isSealdOnly={isSealdOnly}
                oldAuction={oldAuction}
                oldValue={oldAuction && valueGetter(oldAuction)}
                translations={translations}
                value={valueGetter(auction)}
                withDiff={!!oldAuction}
                withEncryptionAccess={withEncryptionAccess}
              />
            </div>
          );
        },
      )}
    </div>
  );
}

function PrintCard({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  return (
    <div style={{ padding: padding(2, 3) }}>
      {title && <Title>{title}</Title>}
      <VerticalLayout>{children}</VerticalLayout>
    </div>
  );
}

export function CareproviderInfoCard({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  const print = usePrint();
  if (print) return <PrintCard title={title}>{children}</PrintCard>;

  return (
    <InfoCard title={title} cardMargin={margin(0, 0, 2, 0)}>
      <CardContentContainer padding={padding(2, 3)}>
        <VerticalLayout>{children}</VerticalLayout>
      </CardContentContainer>
    </InfoCard>
  );
}

export function ClinicInfoCard({
  children,
  noDivider,
  title,
}: {
  children: React.ReactNode;
  noDivider?: boolean;
  title?: string;
}) {
  return (
    <InfoCardLite noDivider={noDivider} cardMargin={margin(0)} title={title}>
      <VerticalLayout width="100%">{children}</VerticalLayout>
    </InfoCardLite>
  );
}
