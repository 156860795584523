import { ASSESSMENT_SLUG_FIRST_STEP } from "core/consts";
import { AssessmentSlug, Auction } from "core/types";
import { createContext, ReactNode, useContext } from "react";

type PatientInfoContextType = {
  Card: ({
    children,
  }: {
    children: ReactNode;
    noDivider?: boolean;
    title: string | undefined;
  }) => JSX.Element;
  auction: Auction | null;
  isClinicApp: boolean;
  withEdit: boolean;
};
type PatientInfoSlugContextType = {
  assessmentSlug: AssessmentSlug | undefined;
  auctionId: number;
  patientId: number;
};

export const INFO_WIDTH = 750;

export const PatientInfoContext = createContext<PatientInfoContextType>({
  Card: function Card({
    children,
  }: {
    children: ReactNode;
    noDivider?: boolean;
    title?: string;
  }) {
    return <>{children}</>;
  },
  withEdit: false,
  isClinicApp: false,
  auction: null,
});

export function usePatientInfoContext(): PatientInfoContextType {
  return useContext(PatientInfoContext);
}

const defaultPatientInfoSlugContext = {
  assessmentSlug: ASSESSMENT_SLUG_FIRST_STEP,
  patientId: -1,
  auctionId: -1,
};

export const PatientInfoSlugContext = createContext<PatientInfoSlugContextType>(
  defaultPatientInfoSlugContext,
);

export function usePatientInfoSlug(): PatientInfoSlugContextType {
  const context = useContext(PatientInfoSlugContext);
  if (!context || context === defaultPatientInfoSlugContext) {
    throw new Error(
      "usePatientInfoSlug must be used within a PatientInfoContext",
    );
  }
  return context;
}
