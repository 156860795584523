import {
  ASSESSMENT_SLUG_MEDICAL,
  ASSESSMENT_SLUG_MENTAL,
  ASSESSMENT_SLUG_MOBILE_SHORT,
  ASSESSMENT_SLUG_MOBILITY,
  CARESEEKER_TYPE_HOSPITAL,
  CORONA_NOT_TESTED,
  CORONA_TESTED_CURED,
  CORONA_TESTED_NEGATIVE,
  CORONA_TESTED_NOT_CURED,
  CORONA_TEST_PENDING,
  CORONA_UNKNOWN,
  INFECTION_STATUS,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SOLUTION_MOBILE_CARE,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
} from "core/consts";
import { descriptiveWhitelist, isRehabSearch } from "core/model/auctions";
import { formatUnixDate } from "core/model/utils/dates";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import {
  Auction,
  CoronaTest,
  GetOntologyType,
  HospitalStay as HospitalStayType,
  InfectionAndGerms as InfectionAndGermsObjectType,
  LimitedCapacity as LimitedCapacityType,
  MentalHealth as MentalHealthType,
  PatientWhitelistDefinition,
  PatientWhitelistScope,
  PhysicalDisabilities as PhysicalDisabilitiesType,
  RehabilitationPotential as RehabilitationPotentialType,
  SearchType,
  Specialization,
  Station,
} from "core/types";
import {
  Divider,
  HorizontalLayout,
  VerticalLayout,
} from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { Body, Subheading, Title } from "ds_legacy/materials/typography";
import { useAuctionRequestContext } from "dsl/atoms/Contexts";
import {
  useCareproviderHandlerUrls,
  useCareseekerHandlerUrls,
} from "dsl/hooks/useNavigationHandlers";
import { usePatientInfoContext } from "dsl/organisms/PatientProfileDetailsShared";
import { IcdValue, StaticIcdCodes } from "dsl/static/StaticIcdCodes";
import { StaticIndependenceScaleResult } from "dsl/static/StaticIndependenceScaleResult";
import { getPredicamentValue } from "react-forms-state";
import { useLocale, useTranslations } from "translations";
import Translations from "translations/types";
import { PatientInfoSlugContext } from "./index";
import { medicalCardWhitelist } from "./modelDefinition";
import {
  BooleanField,
  CareproviderInfoCard,
  Categories,
  Category,
  CategoryContainer,
  CategoryType,
  ClinicInfoCard,
  EmptiableCategory,
  EmptiableField,
  OntologyField,
  StringField,
  isInfectionsAndGermsPredicamentNegative,
  isWhitelisted,
} from "./shared";
import {
  ComponentMappingItem,
  Filter,
  activableInputCollectionIsNotEmpty,
  exists,
  getCategories,
  isNumberAndNotZero,
} from "./utils";

export const showInfection: PatientWhitelistScope[] = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_REHABILITATION,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_TRANSPORT,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [
      SOLUTION_MOBILE_CARE,
      SOLUTION_SHORT_TERM_STATIC_CARE,
      SOLUTION_STATIC_CARE,
    ],
  },
];

function DiagnosisDescription({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <StringField
      testId="diagnosis_description"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      multiLine
      wrap={(content) => (
        <HorizontalLayout stretched width="100%">
          <Category
            category="diagnosis_description"
            title={translations.patient.medicalDiagnosis.mainDiagnosis.label}
          >
            {content}
          </Category>
        </HorizontalLayout>
      )}
    />
  );
}

function SecondaryDiagnosisDescription({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <StringField
      testId="secondary_description"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      multiLine
      wrap={(content) => (
        <HorizontalLayout stretched>
          <Category
            category="secondary_description"
            title={translations.patient.medicalDiagnosis.secondaryDiagnosis}
          >
            {content}
          </Category>
        </HorizontalLayout>
      )}
    />
  );
}

function MorbidityStatus({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: boolean | null | undefined;
  translations: Translations;
  value: boolean | null | undefined;
  withDiff: boolean;
}) {
  return (
    <BooleanField
      label={translations.patient.medicalDiagnosis.multiMorbidPatient}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      wrap={(content) => (
        <HorizontalLayout stretched>
          <CategoryContainer>{content}</CategoryContainer>
        </HorizontalLayout>
      )}
    />
  );
}

function AdditionalSpecializations({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: Array<Specialization> | null | undefined;
  translations: Translations;
  value: Array<Specialization> | null | undefined;
  withDiff: boolean;
}) {
  if (!value?.length && !oldValue?.length) return null;

  return (
    <Category
      category="additional_specializations"
      title={translations.patient.specialisedAdvices}
    >
      <OntologyField
        getOntology={getOntology}
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        type="specializations"
      />
    </Category>
  );
}

function MedicalHistory({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  if (!value && !oldValue) return null;
  return (
    <Category
      category="medical_history"
      title={translations.patient.medicalHistory}
    >
      <StringField
        testId="medical_history"
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        multiLine
      />
    </Category>
  );
}

function AdditionalExaminationsNecessary({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  if (!value && !oldValue) return null;
  return (
    <Category
      category="additional_examinations_necessary"
      title={translations.patient.additionalExaminations}
    >
      <StringField
        testId="additional_examinations_necessary"
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        multiLine
      />
    </Category>
  );
}

function DependencyDiagnosis({
  oldValue,
  value,
  withDiff,
}: {
  oldValue: Auction | undefined;
  value: Auction | undefined;
  withDiff: boolean;
}) {
  const locale = useLocale();
  const translations = useTranslations();
  const { patient } = useCareseekerHandlerUrls();
  const { goToBarthel, goToEarlyRehab, goToSinger } =
    useCareproviderHandlerUrls();
  const { isClinicApp } = usePatientInfoContext();
  const { auctionRequestId, careproviderId } = useAuctionRequestContext();

  if (!value?.patient?.diagnosis?.dependency_diagnosis) return null;

  const dependencyDiagnosis = value?.patient?.diagnosis?.dependency_diagnosis;
  const oldDependencyDiagnosis =
    oldValue?.patient?.diagnosis?.dependency_diagnosis;

  if (withDiff ? !value && !oldValue : !value) return null;

  return (
    <Category
      category="dependency_diagnosis"
      title={translations.patient.autonomy}
    >
      <StaticIndependenceScaleResult
        goTo={
          isClinicApp
            ? patient.goToBarthel({
                auctionId: value.id,
                patientId: value.patient.id,
              })
            : goToBarthel({
                auctionRequestId: +(auctionRequestId || -1),
                careproviderId: +(careproviderId || -1),
              })
        }
        linkLabel={translations.patient.medicalDiagnosis.barthelLink}
        locale={locale}
        testId="barthel-assessment-result"
        title={translations.patient.medicalDiagnosis.barthelIndexPlaceholder}
        translations={translations}
        value={dependencyDiagnosis?.barthel_assessment}
      />
      <StaticIndependenceScaleResult
        goTo={
          isClinicApp
            ? patient.goToSinger({
                auctionId: value.id,
                patientId: value.patient.id,
              })
            : goToSinger({
                auctionRequestId: +(auctionRequestId || -1),
                careproviderId: +(careproviderId || -1),
              })
        }
        linkLabel={
          translations.patient.medicalDiagnosis.singerPatientProfile.singerLink
        }
        locale={locale}
        testId="singer-assessment-result"
        title={translations.patient.medicalDiagnosis.singerIndexPlaceholder}
        translations={translations}
        value={dependencyDiagnosis?.singer_assessment}
      />
      <StaticIndependenceScaleResult
        goTo={
          isClinicApp
            ? patient.goToEarlyRehab({
                auctionId: value.id,
                patientId: value.patient.id,
              })
            : goToEarlyRehab({
                auctionRequestId: +(auctionRequestId || -1),
                careproviderId: +(careproviderId || -1),
              })
        }
        linkLabel={
          translations.patient.medicalDiagnosis.earlyRehabIndex.earlyRehabLink
        }
        locale={locale}
        testId="early-rehab-assessment-result"
        title={
          translations.patient.medicalDiagnosis
            .earlyRehabBarthelIndexPlaceholder
        }
        translations={translations}
        value={dependencyDiagnosis?.early_rehab_assessment}
      />
      <EmptiableField
        title={translations.patient.needsHelpWalking}
        value={getPredicamentValue({
          translations,
          value: dependencyDiagnosis?.requires_walking_aid,
        })}
        testId="requires_walking_aid"
        oldValue={getPredicamentValue({
          translations,
          value: oldDependencyDiagnosis?.requires_walking_aid,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
      <EmptiableField
        title={translations.patient.needsHelpEating}
        value={getPredicamentValue({
          translations,
          value: dependencyDiagnosis?.requires_support_with_feeding,
        })}
        testId="requires_support_with_feeding"
        oldValue={getPredicamentValue({
          translations,
          value: oldDependencyDiagnosis?.requires_support_with_feeding,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
      <EmptiableField
        title={translations.patient.agitationOrientation}
        value={getPredicamentValue({
          translations,
          value: dependencyDiagnosis?.agitated,
        })}
        testId="agitated"
        oldValue={getPredicamentValue({
          translations,
          value: oldDependencyDiagnosis?.agitated,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
      <StringField
        testId="agitated_description"
        value={dependencyDiagnosis?.agitated_description}
        oldValue={oldDependencyDiagnosis?.agitated_description}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.hasHelpAtHome}
        value={getPredicamentValue({
          translations,
          value: dependencyDiagnosis?.has_help_at_home,
        })}
        testId="has_help_at_home"
        oldValue={getPredicamentValue({
          translations,
          value: oldDependencyDiagnosis?.has_help_at_home,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.shortTerm}
        value={getPredicamentValue({
          translations,
          value: dependencyDiagnosis?.returning_home_short_term,
        })}
        testId="returning_home_short_term"
        oldValue={getPredicamentValue({
          translations,
          value: oldDependencyDiagnosis?.returning_home_short_term,
        })}
        withDiff={withDiff}
        noEmptyValue
      />
    </Category>
  );
}

function Dysphagia({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.careNeeds.dysphagia.label}
      category="dysphagia"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      testId="dysphagia"
    />
  );
}

function ChronicPains({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.chronicPains}
      category="chronic_pains"
      testId="chronic_pains"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Allergies({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.allergies}
      category="allergies"
      testId="allergies"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function IcdCodes({
  oldValue,
  value,
  withDiff,
}: {
  oldValue: IcdValue | null | undefined;
  value: IcdValue | null | undefined;
  withDiff: boolean;
}) {
  const translations = useTranslations();
  return (
    <CategoryContainer className="icd_codes">
      <StaticIcdCodes
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        translations={translations}
      />
    </CategoryContainer>
  );
}

type InfectionAndGermsType = {
  infection_and_germs?: InfectionAndGermsObjectType;
  infection_and_germs_state?: number;
};

export function InfectionAndGerms({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: InfectionAndGermsType | null | undefined;
  translations: Translations;
  value: InfectionAndGermsType;
  withDiff: boolean;
}) {
  const [negativeState, negativeStateMessage] =
    isInfectionsAndGermsPredicamentNegative(value, translations);
  const [oldStateNegativeState] = isInfectionsAndGermsPredicamentNegative(
    oldValue,
    translations,
  );
  const withNegativePredicament = withDiff
    ? negativeState && oldStateNegativeState
    : negativeState;
  return (
    <Category
      category="infection_and_germs"
      title={translations.patient.medicalDiagnosis.infections}
    >
      {withNegativePredicament ? (
        <Body>{negativeStateMessage}</Body>
      ) : (
        <>
          <BooleanField
            value={value?.infection_and_germs?.requires_isolation}
            oldValue={oldValue?.infection_and_germs?.requires_isolation}
            label={
              translations.patient.medicalDiagnosis.infectionsRequiresIsolation
            }
            withDiff={withDiff}
          />
          <BooleanField
            value={value?.infection_and_germs?.mrsa}
            oldValue={oldValue?.infection_and_germs?.mrsa}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsMRSA}
          />
          <BooleanField
            value={value?.infection_and_germs?.clostridien}
            oldValue={oldValue?.infection_and_germs?.clostridien}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsClostridien}
          />
          <BooleanField
            value={value?.infection_and_germs?.three_mrgn}
            oldValue={oldValue?.infection_and_germs?.three_mrgn}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsThreeMRGN}
          />
          <BooleanField
            value={value?.infection_and_germs?.four_mrgn}
            oldValue={oldValue?.infection_and_germs?.four_mrgn}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsFourMRGN}
          />
          <BooleanField
            value={value?.infection_and_germs?.vre}
            oldValue={oldValue?.infection_and_germs?.vre}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsVRE}
          />
          <EmptiableField
            prefix={translations.patient.medicalDiagnosis.infectionsOther.label}
            value={value?.infection_and_germs?.other}
            oldValue={oldValue?.infection_and_germs?.other}
            withDiff={withDiff}
            testId="infection_and_germs_other"
          />
        </>
      )}
      <EmptiableField
        value={value?.infection_and_germs?.remarks || ""}
        oldValue={oldValue?.infection_and_germs?.remarks}
        withDiff={withDiff}
        noEmptyValue={
          value?.infection_and_germs_state !== INFECTION_STATUS.UNKNOWN
        }
        testId="infection_and_germs_remarks"
      />
    </Category>
  );
}

export const coronaSwitch = (
  translations: Translations,
  value: CoronaTest,
): string | undefined => {
  if (!value) {
    return undefined;
  }

  if (value.corona_state === CORONA_NOT_TESTED) {
    return translations.coronaTesting.patientNotTested;
  }

  if (value.corona_state === CORONA_UNKNOWN) {
    return translations.coronaTesting.unknown;
  }

  const testResult = value.corona?.test_result;
  if (!testResult) {
    return undefined;
  }

  switch (testResult) {
    case CORONA_TESTED_CURED:
      return `${translations.coronaTesting.results}${translations.general.colon} ${translations.coronaTesting.positiveCured}`;
    case CORONA_TESTED_NOT_CURED:
      return `${translations.coronaTesting.results}${translations.general.colon} ${translations.coronaTesting.positiveUncured}`;
    case CORONA_TESTED_NEGATIVE:
      return translations.coronaTesting.negative;
    case CORONA_TEST_PENDING:
      return `${translations.coronaTesting.results}${translations.general.colon} ${translations.coronaTesting.pending}`;
    default:
      return undefined;
  }
};

function getVaccinatedValue({
  translations,
  value,
}: {
  translations: Translations;
  value: CoronaTest;
}) {
  if (value?.corona?.vaccinated === "") {
    return translations.coronaTesting.hasBeenVaccinated;
  }
  if (typeof value?.corona?.vaccinated === "string") {
    return `${translations.coronaTesting.hasBeenVaccinated}\n ${value.corona.vaccinated}`;
  }
  return translations.coronaTesting.vaccinationUnknown;
}

export function CoronaVirusTesting({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: CoronaTest;
  translations: Translations;
  value: CoronaTest;
  withDiff: boolean;
}) {
  const label = coronaSwitch(translations, value);
  const oldLabel = coronaSwitch(translations, oldValue);

  return (
    <CategoryContainer className="corona_testing">
      <HorizontalLayout>
        <Subheading bold>{translations.coronaTesting.auctionLabel}</Subheading>
      </HorizontalLayout>
      <VerticalLayout margin={margin(0, 0, 1, 0)}>
        <EmptiableField
          value={label}
          oldValue={oldLabel}
          withDiff={withDiff}
          testId="corona_testing"
        />
        <EmptiableField
          value={value?.corona?.description}
          oldValue={oldValue?.corona?.description}
          withDiff={withDiff}
          testId="corona_testing_description"
        />
      </VerticalLayout>
      <VerticalLayout margin={margin(1, 0, 1, 0)}>
        <EmptiableField
          value={getVaccinatedValue({ value, translations })}
          oldValue={getVaccinatedValue({ value: oldValue, translations })}
          withDiff={withDiff}
          testId="corona_testing_vaccination"
        />
      </VerticalLayout>
    </CategoryContainer>
  );
}

function Addictions({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.addictions}
      category="addictions"
      testId="addictions"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Vigilance({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="vigilance"
      label={translations.ontologies.vigilance.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function PatientOrientation({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="orientation"
      label={translations.ontologies.orientation.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function PatientMotivation({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="motivation"
      label={translations.ontologies.motivation.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function PatientCommunication({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="communication"
      label={translations.ontologies.communication.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Dementia({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.dementia}
      category="dementia"
      testId="dementia"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function RunAway({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.closedAreaNeeded}
      category="run_away"
      testId="run_away"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function ProtectedAreaNeeded({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mentalState.protectedAreaNeeded.label}
      category="protected_area_needed"
      testId="protected_area_needed"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Nightwatch({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mentalState.nightwatch.label}
      category="night_watch"
      testId="night_watch"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function FacilityCharacteristics({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: MentalHealthType | null | undefined;
  translations: Translations;
  value: MentalHealthType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="factility_characteristics"
      title={translations.patient.mentalState.facilityCharacteristics}
    >
      <BooleanField
        label={translations.patient.mentalState.closedStationRequired}
        value={value?.closed_station}
        oldValue={oldValue?.closed_station}
        withDiff={withDiff}
      />
      <BooleanField
        label={translations.patient.mentalState.fixationRequired}
        value={value?.fixation}
        oldValue={oldValue?.fixation}
        withDiff={withDiff}
      />
    </Category>
  );
}

function MentalIssues({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: MentalHealthType | null | undefined;
  translations: Translations;
  value: MentalHealthType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="mental_health"
      title={translations.patient.mentalState.mentalIssues}
    >
      <BooleanField
        value={value?.depression}
        oldValue={oldValue?.depression}
        label={translations.patient.mentalState.depression}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.anxiety}
        oldValue={oldValue?.anxiety}
        label={translations.patient.mentalState.anxiety}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.aggressivity}
        oldValue={oldValue?.aggressivity}
        label={translations.patient.mentalState.aggressivity}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.dangerous}
        oldValue={oldValue?.dangerous}
        label={translations.patient.mentalState.dangerous}
        withDiff={withDiff}
      />
      <StringField
        testId="mental_issues"
        prefix={translations.patient.mentalState.mentalIssuesOther}
        value={value?.mental_issues}
        oldValue={oldValue?.mental_issues}
        withDiff={withDiff}
      />
    </Category>
  );
}

function PatientMobility({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: PhysicalDisabilitiesType | null | undefined;
  translations: Translations;
  value: PhysicalDisabilitiesType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category category="mobility" title={translations.ontologies.mobility.key}>
      <OntologyField
        getOntology={getOntology}
        value={value?.mobility}
        oldValue={oldValue?.mobility}
        withDiff={withDiff}
        type="mobility"
      />
      <StringField
        testId="mobility_additional_information"
        value={value?.mobility_additional_information}
        oldValue={oldValue?.mobility_additional_information}
        withDiff={withDiff}
      />
    </Category>
  );
}

function VisualImpairment({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.visual_impairment.label}
      category="visual_impairment"
      testId="visual_impairment"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function AuditiveImpairment({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.auditive_impairment.label}
      category="auditive_impairment"
      testId="auditive_impairment"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

type AssistiveEquipmentType = Pick<
  PhysicalDisabilitiesType,
  | "assistive_equipment"
  | "existing_medical_supplies"
  | "prescribed_medical_supplies"
  | "required_medical_supplies"
>;

function AssistiveEquipment({
  notEmpty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  notEmpty: boolean;
  oldValue: AssistiveEquipmentType;
  translations: Translations;
  value: AssistiveEquipmentType;
  withDiff: boolean;
}) {
  const {
    assistive_equipment,
    existing_medical_supplies,
    prescribed_medical_supplies,
    required_medical_supplies,
  } = value;

  const newValue =
    !!existing_medical_supplies ||
    !!prescribed_medical_supplies ||
    !!required_medical_supplies;

  return newValue ? (
    <EmptiableCategory
      empty={notEmpty}
      title={translations.patient.mobility.assistiveEquipment}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.medicalSupplies.existingMedicalSupplies}
        value={existing_medical_supplies}
        oldValue={oldValue?.existing_medical_supplies}
        testId="existing_medical_supplies"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalSupplies.prescribedMedicalSupplies}
        value={prescribed_medical_supplies}
        oldValue={oldValue?.prescribed_medical_supplies}
        testId="prescribed_medical_supplies"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalSupplies.neededMedicalSupplies}
        value={required_medical_supplies}
        oldValue={oldValue?.required_medical_supplies}
        testId="required_medical_supplies"
        withDiff={withDiff}
      />
    </EmptiableCategory>
  ) : (
    <EmptiableField
      title={translations.patient.mobility.assistiveEquipment}
      value={assistive_equipment}
      oldValue={oldValue?.assistive_equipment}
      testId="assistive_equipment"
      category="assistive_equipment"
      withDiff={withDiff}
    />
  );
}

function Orthosis({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.orthese.label}
      category="orthosis"
      testId="orthosis"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Prothesis({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.prothese.label}
      category="prothese"
      testId="prothese"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}
type HospitalStayProps = {
  hospital_stay?: HospitalStayType;
  search_type?: SearchType;
  station_full?: Station;
};

function HospitalStay({
  forClinic,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  forClinic: boolean | null | undefined;
  oldValue?: HospitalStayProps | null;
  translations: Translations;
  value?: HospitalStayProps | null;
  withDiff: boolean;
}) {
  const locale = useLocale();

  const isReha = isRehabSearch(value?.search_type);

  const hasCommonFields =
    !!value?.hospital_stay?.doctor_in_charge_in_hospital_phone ||
    !!value?.station_full?.name;

  const hasRehaFields =
    isReha &&
    (!!value?.hospital_stay?.operation_date ||
      !!value?.hospital_stay?.operation_description ||
      !!value?.hospital_stay?.geriatric_early_stage ||
      !!value?.hospital_stay?.doctor_in_charge_in_hospital);

  const notEmpty = hasCommonFields || hasRehaFields;

  const showHospitalStay = forClinic ? isReha || notEmpty : isReha && notEmpty;

  if (!showHospitalStay) return null;

  return (
    <EmptiableCategory
      title={translations.patient.medicalDiagnosis.hospitalStay}
      empty={!notEmpty}
    >
      <EmptiableField
        title={translations.patient.medicalDiagnosis.doctorInChargeInHospital}
        value={value?.hospital_stay?.doctor_in_charge_in_hospital}
        oldValue={oldValue?.hospital_stay?.doctor_in_charge_in_hospital}
        withDiff={withDiff}
        noEmptyValue
        testId="doctor_in_charge_in_hospital"
      />
      <EmptiableField
        title={translations.patient.station}
        value={value?.station_full?.name}
        oldValue={oldValue?.station_full?.name}
        withDiff={withDiff}
        testId="station_full_name"
      />
      <EmptiableField
        noEmptyValue
        title={translations.patient.stationPhoneNumber}
        value={value?.hospital_stay?.doctor_in_charge_in_hospital_phone}
        oldValue={oldValue?.hospital_stay?.doctor_in_charge_in_hospital_phone}
        withDiff={withDiff}
        testId="doctor_in_charge_in_hospital_phone"
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.operationDate}
        value={
          (value?.hospital_stay?.operation_date &&
            formatUnixDate(value?.hospital_stay?.operation_date, locale)) ||
          null
        }
        testId="operation_date"
        oldValue={
          (oldValue?.hospital_stay?.operation_date &&
            formatUnixDate(oldValue?.hospital_stay?.operation_date, locale)) ||
          null
        }
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.operationDescription}
        value={value?.hospital_stay?.operation_description}
        oldValue={oldValue?.hospital_stay?.operation_description}
        withDiff={withDiff}
        noEmptyValue
        testId="operation_description"
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.conductedEarlyRehab}
        value={value?.hospital_stay?.geriatric_early_stage}
        oldValue={oldValue?.hospital_stay?.geriatric_early_stage}
        withDiff={withDiff}
        testId="geriatric_early_stage"
      />
    </EmptiableCategory>
  );
}

function RehabilitationPotential({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: RehabilitationPotentialType | null | undefined;
  translations: Translations;
  value: RehabilitationPotentialType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="rehabilitation_potential"
      title={translations.patient.medicalDiagnosis.rehabilitationPotential}
    >
      <OntologyField
        getOntology={getOntology}
        value={value?.evaluation}
        oldValue={oldValue?.evaluation}
        withDiff={withDiff}
        type="rehabilitationPotential"
      />
      <StringField
        testId="evaluation_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </Category>
  );
}

function RehabilitationGoals({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.rehabilitationGoals}
      category="rehabilitation_goals"
      testId="rehabilitation_goals"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function LimitedCapacity({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: LimitedCapacityType | null | undefined;
  translations: Translations;
  value: LimitedCapacityType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.medicalDiagnosis.limitations}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={
          translations.patient.medicalDiagnosis.limitedPsychologicalCapacity
        }
        value={value?.limited_psychological_capacity}
        oldValue={oldValue?.limited_psychological_capacity}
        testId="limited_psychological_capacity"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedCognitiveCapacity}
        value={value?.limited_cognitive_capacity}
        oldValue={oldValue?.limited_cognitive_capacity}
        testId="limited_cognitive_capacity"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedSocialBehaviour}
        value={value?.limited_social_behaviour}
        oldValue={oldValue?.limited_social_behaviour}
        testId="limited_social_behaviour"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedPsychicalCapacity}
        value={value?.limited_physical_capacity}
        oldValue={oldValue?.limited_physical_capacity}
        testId="limited_physical_capacity"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedCapacityOther}
        value={value?.other}
        oldValue={oldValue?.other}
        testId="limited_other"
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function filterCategories(
  categoryKeysToFilter: Array<string>,
  categories: Array<CategoryType>,
) {
  return categories.filter((category) =>
    categoryKeysToFilter.includes(category.key),
  );
}

const medicalDiagnosisFields = [
  "main_diagnosis",
  "secondary_diagnosis",
  "medical_history",
  "additional_examinations_necessary",
  "multimorbidity",
  "icd_codes",
  "dependencyDiagnosis",
  "dysphagia",
  "chronic_pains",
  "allergies",
  "infection_and_germs",
  "addictions",
  "hospital_stay",
  "corona_testing",
  "rehabilitation_potential",
  "limited_capacity",
  "rehabilitation_goals",
  "additional_specializations",
];

const mentalStateFields = [
  "vigilance",
  "patient_orientation",
  "patient_motivation",
  "patient_communication",
  "dementia",
  "run_away",
  "protected_area_needed",
  "night_watch",
  "facility_characteristics",
  "mental_issues",
];

const physicalStateFields = [
  "patient_mobility",
  "visual_impairment",
  "auditive_impairment",
  "assistive_equipment",
  "orthosis",
  "prothesis",
];

export function dependencyDiagnosisExists(auction: Auction | undefined) {
  if (!auction?.patient?.diagnosis?.dependency_diagnosis) {
    return false;
  }

  const {
    agitated,
    barthel_assessment,
    dependency_level,
    early_rehab_assessment,
    has_help_at_home,
    requires_support_with_feeding,
    requires_walking_aid,
    returning_home_short_term,
    singer_assessment,
  } = auction.patient.diagnosis.dependency_diagnosis;

  const hasValidDependencyLevel = isNumberAndNotZero(dependency_level);

  const hasBarthel = !!(
    barthel_assessment?.date && typeof barthel_assessment.score === "number"
  );

  const hasSinger = !!(
    singer_assessment?.date && typeof singer_assessment.score === "number"
  );

  const hasEarlyRehab = !!(
    early_rehab_assessment?.date &&
    typeof early_rehab_assessment.score === "number"
  );

  const requiresWalkingAid = isNumberAndNotZero(requires_walking_aid);
  const requiresSupportWithFeeding = isNumberAndNotZero(
    requires_support_with_feeding,
  );
  const isAgitated = isNumberAndNotZero(agitated);
  const hasHelpAtHome = isNumberAndNotZero(has_help_at_home);
  const returningHomeShortTerm = isNumberAndNotZero(returning_home_short_term);

  return (
    hasValidDependencyLevel ||
    hasBarthel ||
    hasSinger ||
    hasEarlyRehab ||
    requiresWalkingAid ||
    requiresSupportWithFeeding ||
    isAgitated ||
    hasHelpAtHome ||
    returningHomeShortTerm
  );
}

export const medicalFilters: Record<string, Filter> = {
  main_diagnosis: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.main_diagnosis,
    exists: (auction) => auction?.patient?.diagnosis?.main_diagnosis != null,
  },
  secondary_diagnosis: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.secondary_diagnosis,
    exists: (auction) =>
      auction?.patient?.diagnosis?.secondary_diagnosis != null,
  },
  multimorbidity: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.multimorbidity,
    exists: (auction) => auction?.patient?.diagnosis?.multimorbidity != null,
  },
  additional_specializations: {
    valueGetter: (auction) => auction?.additional_specializations,
    exists: (auction) => !!auction?.additional_specializations?.length,
  },
  medical_history: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.medical_history,
    exists: (auction) => auction?.patient?.diagnosis?.medical_history != null,
  },
  additional_examinations_necessary: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.additional_examinations_necessary,
    exists: (auction) =>
      auction?.patient?.diagnosis?.additional_examinations_necessary != null,
  },
  icd_codes: {
    valueGetter: (auction) => ({
      icd_code: auction?.patient?.diagnosis?.icd_code,
      additional_icd_codes: auction?.patient?.diagnosis?.additional_icd_codes,
    }),
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;

      return !!(diagnosis.icd_code || !!diagnosis.additional_icd_codes?.length);
    },
  },
  infection_and_germs: {
    valueGetter: (auction) => ({
      infection_and_germs_state:
        auction?.patient?.diagnosis?.infection_and_germs_state,
      infection_and_germs: {
        description:
          auction?.patient?.diagnosis?.infection_and_germs?.description,
        requires_isolation:
          auction?.patient?.diagnosis?.infection_and_germs
            ?.requires_isolation || false,
        remarks: auction?.patient?.diagnosis?.infection_and_germs?.remarks,
        mrsa: auction?.patient?.diagnosis?.infection_and_germs?.mrsa || false,
        clostridien:
          auction?.patient?.diagnosis?.infection_and_germs?.clostridien ||
          false,
        three_mrgn:
          auction?.patient?.diagnosis?.infection_and_germs?.three_mrgn || false,
        four_mrgn:
          auction?.patient?.diagnosis?.infection_and_germs?.four_mrgn || false,
        vre: auction?.patient?.diagnosis?.infection_and_germs?.vre || false,
        other: auction?.patient?.diagnosis?.infection_and_germs?.other,
      },
    }),
    exists: (auction) =>
      auction?.patient?.diagnosis?.infection_and_germs_state != null,
  },
  dependencyDiagnosis: {
    valueGetter: (auction) => auction,
    exists: dependencyDiagnosisExists,
  },
  dysphagia: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.dysphagia,
    exists: (auction) => auction?.patient?.diagnosis?.dysphagia != null,
  },
  chronic_pains: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.chronic_pains,
    exists: (auction) => auction?.patient?.diagnosis?.chronic_pains != null,
  },
  allergies: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.allergies,
    exists: (auction) => auction?.patient?.diagnosis?.allergies != null,
  },
  addictions: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.addictions,
    exists: (auction) => auction?.patient?.diagnosis?.addictions != null,
  },
  vigilance: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.vigilance,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return (
        diagnosis.mental_health?.vigilance != null &&
        diagnosis.mental_health.vigilance > 0
      );
    },
  },
  patient_orientation: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.orientation,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return !!(
        diagnosis.mental_health?.orientation &&
        diagnosis.mental_health.orientation != 0
      );
    },
  },
  patient_motivation: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.motivation,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return !!(
        diagnosis.mental_health?.motivation &&
        diagnosis.mental_health.motivation != 0
      );
    },
  },
  patient_communication: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.communication,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return !!(
        diagnosis.mental_health?.communication &&
        diagnosis.mental_health.communication != 0
      );
    },
  },
  dementia: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.dementia_description,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return diagnosis.mental_health?.dementia_description != null;
    },
  },
  run_away: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.run_away_description,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return diagnosis.mental_health?.run_away_description != null;
    },
  },
  protected_area_needed: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.protected_area_needed,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return diagnosis.mental_health?.protected_area_needed != null;
    },
  },
  night_watch: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.night_watch_description,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return diagnosis.mental_health?.night_watch_description != null;
    },
  },
  facility_characteristics: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.mental_health,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis?.mental_health) return false;
      return (
        diagnosis.mental_health.closed_station == true ||
        diagnosis.mental_health.fixation == true
      );
    },
  },
  mental_issues: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.mental_health,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return !!(
        diagnosis.mental_health?.mental_issues ||
        diagnosis.mental_health?.depression == true ||
        diagnosis.mental_health?.anxiety == true ||
        diagnosis.mental_health?.aggressivity == true ||
        diagnosis.mental_health?.dangerous == true
      );
    },
  },
  patient_mobility: {
    valueGetter: (auction) => ({
      mobility: auction?.patient?.diagnosis?.physical_disabilities?.mobility,
      mobility_additional_information:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.mobility_additional_information,
    }),
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis?.physical_disabilities) return false;
      return (
        (diagnosis.physical_disabilities.mobility &&
          diagnosis.physical_disabilities.mobility > 0) ||
        diagnosis.physical_disabilities.mobility_additional_information != null
      );
    },
  },
  visual_impairment: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.visual_impairment,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return diagnosis.physical_disabilities?.visual_impairment != null;
    },
  },
  auditive_impairment: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.auditive_impairment,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return diagnosis.physical_disabilities?.auditive_impairment != null;
    },
  },
  assistive_equipment: {
    valueGetter: (auction) => ({
      assistive_equipment:
        auction?.patient?.diagnosis?.physical_disabilities?.assistive_equipment,
      prescribed_medical_supplies:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.prescribed_medical_supplies,
      existing_medical_supplies:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.existing_medical_supplies,
      required_medical_supplies:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.required_medical_supplies,
    }),
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return (
        diagnosis.physical_disabilities?.assistive_equipment != null ||
        diagnosis.physical_disabilities?.prescribed_medical_supplies != null ||
        diagnosis.physical_disabilities?.existing_medical_supplies != null ||
        diagnosis.physical_disabilities?.required_medical_supplies != null
      );
    },
    notEmpty: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities
        ?.prescribed_medical_supplies != null ||
      auction?.patient?.diagnosis?.physical_disabilities
        ?.existing_medical_supplies != null ||
      auction?.patient?.diagnosis?.physical_disabilities
        ?.required_medical_supplies != null,
  },
  orthosis: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.orthese,
    exists: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.orthese != null,
  },
  prothesis: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.prothese,
    exists: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.prothese != null,
  },
  hospital_stay: {
    valueGetter: (auction) => ({
      station_full: auction?.patient?.station_full,
      hospital_stay: auction?.patient?.diagnosis?.hospital_stay,
      search_type: auction?.search_type,
    }),
    exists: (auction, forClinic) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;

      const isReha = isRehabSearch(auction?.search_type);

      const hasCommonFields =
        !!diagnosis.hospital_stay?.doctor_in_charge_in_hospital_phone ||
        !!auction.patient?.station_full?.name;

      const hasRehaFields =
        isReha &&
        (!!diagnosis.hospital_stay?.operation_date ||
          !!diagnosis.hospital_stay?.operation_description ||
          !!diagnosis.hospital_stay?.geriatric_early_stage ||
          !!diagnosis.hospital_stay?.doctor_in_charge_in_hospital);

      const notEmpty = hasCommonFields || hasRehaFields;

      const showHospitalStay = forClinic
        ? isReha || notEmpty
        : isReha && notEmpty;

      if (!showHospitalStay) return false;

      return true;
    },
  },
  rehabilitation_potential: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.rehabilitation_potential,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return (
        diagnosis.rehabilitation_potential != null &&
        (diagnosis.rehabilitation_potential.description != null ||
          diagnosis.rehabilitation_potential.evaluation != 0)
      );
    },
  },
  limited_capacity: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.limited_capacity,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return !!(
        diagnosis.limited_capacity != null &&
        Object.keys(diagnosis.limited_capacity).length &&
        Object.keys(diagnosis.limited_capacity).filter((prop) => prop !== null)
          .length
      );
    },
    notEmpty: (auction) => {
      const limited_capacity = auction?.patient.diagnosis?.limited_capacity;
      return (
        !!limited_capacity &&
        activableInputCollectionIsNotEmpty(limited_capacity)
      );
    },
  },
  rehabilitation_goals: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.rehabilitation_goals,
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return (
        typeof diagnosis.rehabilitation_goals === "string" &&
        diagnosis.rehabilitation_goals.trim() !== ""
      );
    },
  },
  corona_testing: {
    valueGetter: (auction) => ({
      corona: auction?.patient?.diagnosis?.corona,
      corona_state: auction?.patient?.diagnosis?.corona_state,
    }),
    exists: (auction) => {
      const diagnosis = auction?.patient?.diagnosis;
      if (!diagnosis) return false;
      return (
        typeof diagnosis?.corona_state === "number" ||
        typeof diagnosis?.corona?.test_result === "number" ||
        !!diagnosis?.corona?.description
      );
    },
  },
};

function getWhitelist({ formInputValue }: { formInputValue: Auction }) {
  return {
    main_diagnosis: true,
    secondary_diagnosis: true,
    multimorbidity: true,
    medical_history: true,
    additional_examinations_necessary: true,
    icd_codes: true,
    additional_specializations: true,
    infection_and_germs: descriptiveWhitelist(showInfection)({
      formInputValue,
    }),
    dependencyDiagnosis: true,
    dysphagia: true,
    chronic_pains: true,
    allergies: true,
    addictions: true,
    vigilance: true,
    patient_orientation: true,
    patient_motivation: true,
    patient_communication: true,
    dementia: true,
    run_away: true,
    night_watch: true,
    facility_characteristics: true,
    mental_issues: true,
    patient_mobility: true,
    visual_impairment: true,
    auditive_impairment: true,
    assistive_equipment: true,
    orthosis: true,
    prothesis: true,
    hospital_stay: true,
    corona_testing: true,
    rehabilitation_potential: true,
    limited_capacity: true,
    rehabilitation_goals: true,
    protected_area_needed: true,
  };
}

const medicalComponentMapping: ComponentMappingItem[] = [
  {
    Component: DiagnosisDescription,
    key: "main_diagnosis",
    fullWidth: true,
  },
  {
    Component: SecondaryDiagnosisDescription,
    key: "secondary_diagnosis",
    fullWidth: true,
  },
  {
    Component: MorbidityStatus,
    key: "multimorbidity",
    fullWidth: true,
  },
  {
    Component: AdditionalSpecializations,
    key: "additional_specializations",
  },
  {
    Component: AdditionalExaminationsNecessary,
    key: "additional_examinations_necessary",
  },
  {
    Component: MedicalHistory,
    key: "medical_history",
  },
  {
    Component: IcdCodes,
    key: "icd_codes",
  },
  {
    Component: InfectionAndGerms,
    key: "infection_and_germs",
  },
  {
    Component: CoronaVirusTesting,
    key: "corona_testing",
  },
  {
    Component: DependencyDiagnosis,
    key: "dependencyDiagnosis",
  },
  {
    Component: Dysphagia,
    key: "dysphagia",
  },
  {
    Component: ChronicPains,
    key: "chronic_pains",
  },
  {
    Component: Allergies,
    key: "allergies",
  },
  {
    Component: Addictions,
    key: "addictions",
  },
  {
    Component: Vigilance,
    key: "vigilance",
  },
  {
    Component: PatientOrientation,
    key: "patient_orientation",
  },
  {
    Component: PatientMotivation,
    key: "patient_motivation",
  },
  {
    Component: PatientCommunication,
    key: "patient_communication",
  },
  {
    Component: Dementia,
    key: "dementia",
  },
  {
    Component: RunAway,
    key: "run_away",
  },
  {
    Component: ProtectedAreaNeeded,
    key: "protected_area_needed",
  },
  {
    Component: Nightwatch,
    key: "night_watch",
  },
  {
    Component: FacilityCharacteristics,
    key: "facility_characteristics",
  },
  {
    Component: MentalIssues,
    key: "mental_issues",
  },
  {
    Component: PatientMobility,
    key: "patient_mobility",
  },
  {
    Component: VisualImpairment,
    key: "visual_impairment",
  },
  {
    Component: AuditiveImpairment,
    key: "auditive_impairment",
  },
  {
    Component: AssistiveEquipment,
    key: "assistive_equipment",
  },
  {
    Component: Orthosis,
    key: "orthosis",
  },
  {
    Component: Prothesis,
    key: "prothesis",
  },
  {
    Component: HospitalStay,
    key: "hospital_stay",
  },
  {
    Component: RehabilitationPotential,
    key: "rehabilitation_potential",
  },
  {
    Component: LimitedCapacity,
    key: "limited_capacity",
  },
  {
    Component: RehabilitationGoals,
    key: "rehabilitation_goals",
  },
];

const showMedicalCard = (auction: Auction) => {
  if (auction.assessment_variant === ASSESSMENT_SLUG_MOBILE_SHORT) return false;
  if (
    (
      [SEARCH_TYPE_HOME_CARE, SEARCH_TYPE_MEDICAL_SUPPLIES] as SearchType[]
    ).includes(auction.search_type)
  )
    return false;
  return true;
};

export function MedicalCard({
  auction,
  forClinic,
  oldAuction,
}: {
  auction: Auction;
  forClinic?: boolean;
  oldAuction?: Auction;
}) {
  const getOntology = useGetOntology();
  const withDiff = oldAuction != null;
  if (
    withDiff
      ? !auction.patient.diagnosis && !oldAuction?.patient.diagnosis
      : !auction.patient.diagnosis
  )
    return null;

  if (
    !isWhitelisted(medicalCardWhitelist, auction, oldAuction) ||
    !showMedicalCard(auction)
  )
    return null;

  return forClinic ? (
    <ClinicMedicalCard
      getOntology={getOntology}
      auction={auction}
      oldAuction={oldAuction}
    />
  ) : (
    <CareproviderMedicalCards
      getOntology={getOntology}
      auction={auction}
      oldAuction={oldAuction}
    />
  );
}

function ClinicMedicalCard({
  auction,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) {
  const categories = getCategories({
    auction,
    componentMapping: medicalComponentMapping,
    filters: medicalFilters,
    forClinic: true,
    getWhitelist,
    oldAuction,
  });

  const translations = useTranslations();

  const clinicTopCategories = filterCategories(
    medicalDiagnosisFields,
    categories,
  );
  const mentalStateCategories = filterCategories(mentalStateFields, categories);
  const physicalStateCategories = filterCategories(
    physicalStateFields,
    categories,
  );

  return (
    <ClinicInfoCard title={translations.patient.medicalDiagnosis.stepperTitle}>
      <PatientInfoSlugContext.Provider
        value={{
          patientId: auction.patient.id,
          auctionId: auction.id,
          assessmentSlug: ASSESSMENT_SLUG_MEDICAL,
        }}
      >
        <Categories
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
          translations={translations}
          categories={clinicTopCategories}
          forClinic
        />
      </PatientInfoSlugContext.Provider>
      {exists(mentalStateCategories) && (
        <>
          <Divider margin={margin(2, 0, 0, 2)} />
          <Title>{translations.patient.mentalHealth}</Title>
          <PatientInfoSlugContext.Provider
            value={{
              patientId: auction.patient.id,
              auctionId: auction.id,
              assessmentSlug: ASSESSMENT_SLUG_MENTAL,
            }}
          >
            <Categories
              getOntology={getOntology}
              auction={auction}
              oldAuction={oldAuction}
              translations={translations}
              categories={mentalStateCategories}
            />
          </PatientInfoSlugContext.Provider>
        </>
      )}
      {exists(physicalStateCategories) && (
        <>
          <Divider margin={margin(2, 0, 0, 2)} />
          <Title>{translations.patient.physicalHealth}</Title>
          <PatientInfoSlugContext.Provider
            value={{
              patientId: auction.patient.id,
              auctionId: auction.id,
              assessmentSlug: ASSESSMENT_SLUG_MOBILITY,
            }}
          >
            <Categories
              getOntology={getOntology}
              auction={auction}
              oldAuction={oldAuction}
              translations={translations}
              categories={physicalStateCategories}
            />
          </PatientInfoSlugContext.Provider>
        </>
      )}
    </ClinicInfoCard>
  );
}

const medicalDiagnosisWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_HOSPITAL,
  },
];

const CareproviderMedicalCards = ({
  auction,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) => {
  const translations = useTranslations();

  const categories = getCategories({
    auction,
    componentMapping: medicalComponentMapping,
    filters: medicalFilters,
    forClinic: false,
    getWhitelist,
    oldAuction,
  });

  const medicalDiagnosisCategories = filterCategories(
    medicalDiagnosisFields,
    categories,
  );

  const mentalStateCategories = filterCategories(mentalStateFields, categories);
  const physicalStateCategories = filterCategories(
    physicalStateFields,
    categories,
  );

  return (
    <>
      {isWhitelisted(medicalDiagnosisWhitelist, auction, oldAuction) && (
        <CareproviderInfoCard
          title={translations.patient.medicalDiagnosis.stepperTitle}
        >
          <Categories
            getOntology={getOntology}
            auction={auction}
            oldAuction={oldAuction}
            translations={translations}
            categories={medicalDiagnosisCategories}
          />
        </CareproviderInfoCard>
      )}
      {exists(mentalStateCategories) && (
        <CareproviderInfoCard title={translations.patient.mentalHealth}>
          <Categories
            getOntology={getOntology}
            auction={auction}
            oldAuction={oldAuction}
            translations={translations}
            categories={mentalStateCategories}
          />
        </CareproviderInfoCard>
      )}
      {exists(physicalStateCategories) && (
        <CareproviderInfoCard title={translations.patient.physicalHealth}>
          <Categories
            getOntology={getOntology}
            auction={auction}
            oldAuction={oldAuction}
            translations={translations}
            categories={physicalStateCategories}
          />
        </CareproviderInfoCard>
      )}
    </>
  );
};
