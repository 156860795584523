import { useEnvContext } from "context/EnvContext";
import { ASSESSMENT_SLUG_NEEDS, SEARCH_TYPE_CARE } from "core/consts";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import {
  AccessDrainages as AccessDrainagesType,
  Auction,
  BaseCare as BaseCareType,
  Breathing as BreathingType,
  Companionship as CompanionshipType,
  CompressionCare as CompressionType,
  Dialysis as DialysisType,
  Food as FoodType,
  HelpAtHome as HelpAtHomeType,
  IncontinenceHelp as IncontinenceType,
  Injection as InjectionType,
  Medication as MedicationType,
  Palliative as PalliativeType,
  PatientNeedMonitoring,
  PatientNeedSupport,
  PsychiatricCare as PsychiatricCareType,
  WoundCare as WoundType,
} from "core/types";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { PatientInfoSlugContext, usePatientInfoContext } from ".";
import {
  BooleanField,
  Categories,
  CategoryDescription,
  EmptiableCategory,
  EmptiableField,
  StringField,
} from "./shared";
import {
  ComponentMappingItem,
  descriptionCategory,
  exists,
  Filter,
  getCategories,
  stringFilled,
} from "./utils";

function Nutrition({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: FoodType | null | undefined;
  translations: Translations;
  value: FoodType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.nutrition}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.parenteralNutrition.label}
        testId="parenteral_nutrition"
        value={value?.parenteral_nutrition}
        oldValue={oldValue?.parenteral_nutrition}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.enteralNutrition.label}
        testId="enteral_nutrition"
        value={value?.enteral_nutrition}
        oldValue={oldValue?.enteral_nutrition}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Incontinence({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: IncontinenceType | null | undefined;
  translations: Translations;
  value: IncontinenceType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.incontinence}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.urinaryIncontinence}
        testId="urinary_incontinence"
        value={value?.urinary_incontinence}
        oldValue={oldValue?.urinary_incontinence}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.fecalIncontinence}
        testId="fecal_incontinence"
        value={value?.fecal_incontinence}
        oldValue={oldValue?.fecal_incontinence}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Breathing({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: BreathingType | null | undefined;
  translations: Translations;
  value: BreathingType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.breathing}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.tracheostomy.label}
        testId="tracheostomy"
        value={value?.tracheostomy}
        oldValue={oldValue?.tracheostomy}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.specialised_intensive_nurse}
        oldValue={oldValue?.specialised_intensive_nurse}
        label={translations.ontologies.service.values.specialisedIntensiveNurse}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.oxygenTherapy.label}
        testId="oxygen_therapy"
        value={value?.oxygen_therapy}
        oldValue={oldValue?.oxygen_therapy}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Dialysis({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: DialysisType | null | undefined;
  translations: Translations;
  value: DialysisType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.dialysis}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.hemodialysis.label}
        testId="hemodialysis"
        value={value?.hemodialysis}
        oldValue={oldValue?.hemodialysis}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.peritonealDialysis.label}
        testId="peritoneal_dialysis"
        value={value?.peritoneal_dialysis}
        oldValue={oldValue?.peritoneal_dialysis}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Wounds({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: WoundType | null | undefined;
  translations: Translations;
  value: WoundType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.woundCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="woundCare_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function PsychiatricCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PsychiatricCareType | null | undefined;
  translations: Translations;
  value: PsychiatricCareType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.psychiatric_care.label}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="psychiatric_care_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Medication({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: MedicationType | null | undefined;
  translations: Translations;
  value: MedicationType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.medication}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="medication_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <EmptiableField
        testId="blood_thinner"
        title={translations.patient.takesBloodThinner}
        value={value?.blood_thinner}
        oldValue={oldValue?.blood_thinner}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Injection({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: InjectionType | null | undefined;
  translations: Translations;
  value: InjectionType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.injection}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="injection_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function AccessDrainages({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: AccessDrainagesType | null | undefined;
  translations: Translations;
  value: AccessDrainagesType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.accessDrainages.accessAndDrainages}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.trachealCannula}
        value={value?.tracheal_cannula}
        testId="tracheal_cannula"
        oldValue={oldValue?.tracheal_cannula}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.gastricTube}
        value={value?.gastric_tube}
        testId="gastric_tube"
        oldValue={oldValue?.gastric_tube}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.careNeeds.accessDrainages.centralVenousCatheter
        }
        value={value?.central_venous_catheter}
        testId="central_venous_catheter"
        oldValue={oldValue?.central_venous_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.careNeeds.accessDrainages
            .peripheralVenousCatheter
        }
        value={value?.peripheral_venous_catheter}
        testId="peripheral_venous_catheter"
        oldValue={oldValue?.peripheral_venous_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.careNeeds.accessDrainages.suprapubicCatheter
        }
        value={value?.suprapubic_catheter}
        testId="suprapubic_catheter"
        oldValue={oldValue?.suprapubic_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.permanentCatheter}
        value={value?.permanent_catheter}
        testId="permanent_catheter"
        oldValue={oldValue?.permanent_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.drainage}
        value={value?.drainage}
        testId="drainage"
        oldValue={oldValue?.drainage}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.intravenousInjection}
        value={value?.intravenous_injection}
        testId="intravenous_injection"
        oldValue={oldValue?.intravenous_injection}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.other}
        value={value?.other_access_drainages}
        testId="other_access_drainages"
        oldValue={oldValue?.other_access_drainages}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Remarks({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.remarks}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        testId="remarks"
      />
    </EmptiableCategory>
  );
}

function Compression({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: CompressionType | null | undefined;
  translations: Translations;
  value: CompressionType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.compression}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="compression_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}
function PalliativeCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PalliativeType | null | undefined;
  translations: Translations;
  value: PalliativeType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.palliativeCare.label}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <CategoryDescription
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.palliative_certificate_needed}
        oldValue={oldValue?.palliative_certificate_needed}
        withDiff={withDiff}
        label={
          translations.patient.medicalDiagnosis.palliativeCertificateNeeded
        }
      />
    </EmptiableCategory>
  );
}

function BaseCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: BaseCareType | null | undefined;
  translations: Translations;
  value: BaseCareType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.baseCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <CategoryDescription
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <StringField
        testId="companionship_grant"
        value={
          value?.companionship_grant
            ? translations.patient.careNeeds.carePaidByHealthInsurance
            : ""
        }
        oldValue={
          oldValue?.companionship_grant
            ? translations.patient.careNeeds.carePaidByHealthInsurance
            : ""
        }
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function BasicMedicalCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: { description: string | null | undefined } | null | undefined;
  translations: Translations;
  value: { description: string | null | undefined } | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.medicalCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="medical_care_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function StaticCareForDisabledPatients({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: { description: string | null | undefined } | null | undefined;
  translations: Translations;
  value: { description: string | null | undefined } | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.ontologies.service.values.staticCareDisabledPatients}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="disabled_patients_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function ImportantInformation({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.titleImportantInformationCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        testId="important_info"
      />
    </EmptiableCategory>
  );
}

function Companionship({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: CompanionshipType | null | undefined;
  translations: Translations;
  value: CompanionshipType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.companionship}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <CategoryDescription
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <StringField
        testId="companionship_grant"
        value={
          value?.companionship_grant
            ? translations.patient.careNeeds.companionshipGrant
            : ""
        }
        oldValue={
          oldValue?.companionship_grant
            ? translations.patient.careNeeds.companionshipGrant
            : ""
        }
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function HelpAtHome({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: HelpAtHomeType | null | undefined;
  translations: Translations;
  value: HelpAtHomeType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.helpAtHome}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="help_at_home_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Support({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PatientNeedSupport | null | undefined;
  translations: Translations;
  value: PatientNeedSupport | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.needSupport}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <BooleanField
        value={value?.eating}
        oldValue={oldValue?.eating}
        label={translations.patient.careNeeds.withEating}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.body_care}
        oldValue={oldValue?.body_care}
        label={translations.patient.careNeeds.withBodyCare}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.dressing}
        oldValue={oldValue?.dressing}
        label={translations.patient.careNeeds.withDressing}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Monitoring({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PatientNeedMonitoring | null | undefined;
  translations: Translations;
  value: PatientNeedMonitoring | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.monitoring}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        prefix={translations.patient.careNeeds.circulation.label}
        value={value?.circulation}
        testId="circulation"
        oldValue={oldValue?.circulation}
        withDiff={withDiff}
      />
      <EmptiableField
        prefix={translations.patient.careNeeds.respiration.label}
        value={value?.respiration}
        testId="respiration"
        oldValue={oldValue?.respiration}
        withDiff={withDiff}
      />
      <EmptiableField
        prefix={translations.patient.careNeeds.metabolism.label}
        value={value?.metabolism}
        testId="metabolism"
        oldValue={oldValue?.metabolism}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

export const careneedsFilters: Record<string, Filter> = {
  palliative_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.palliative,
    exists: (auction) => auction?.patient?.care_needs?.palliative != null,
    notEmpty: (auction) =>
      auction?.patient?.care_needs?.palliative != null &&
      (auction?.patient?.care_needs.palliative.description != null ||
        auction?.patient?.care_needs.palliative.palliative_certificate_needed !=
          null),
  },
  nutrition: {
    valueGetter: (auction) => auction?.patient?.care_needs?.nutrition,
    exists: (auction) => !!auction?.patient?.care_needs?.nutrition,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs?.nutrition &&
      ((auction?.patient?.care_needs.nutrition &&
        stringFilled(
          auction?.patient?.care_needs.nutrition.parenteral_nutrition,
        )) ||
        (auction?.patient?.care_needs.nutrition &&
          stringFilled(
            auction?.patient?.care_needs.nutrition.enteral_nutrition,
          ))) === true,
  },
  incontinence: {
    valueGetter: (auction) => auction?.patient?.care_needs?.incontinence,
    exists: (auction) => !!auction?.patient?.care_needs?.incontinence,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs?.incontinence &&
      (stringFilled(
        auction?.patient?.care_needs.incontinence.urinary_incontinence,
      ) ||
        (auction?.patient?.care_needs.incontinence &&
          stringFilled(
            auction?.patient?.care_needs.incontinence.fecal_incontinence,
          ))) === true,
  },
  breathing: {
    valueGetter: (auction) => auction?.patient?.care_needs?.breathing,
    exists: (auction) => !!auction?.patient?.care_needs?.breathing,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs?.breathing &&
      (stringFilled(auction?.patient?.care_needs.breathing.tracheostomy) ||
        auction?.patient?.care_needs.breathing?.specialised_intensive_nurse ||
        (auction?.patient?.care_needs.breathing &&
          stringFilled(
            auction?.patient?.care_needs.breathing.oxygen_therapy,
          ))) === true,
  },
  Dialysis: {
    valueGetter: (auction) => auction?.patient?.care_needs?.dialysis,
    exists: (auction) => !!auction?.patient?.care_needs?.dialysis,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs?.dialysis &&
      (stringFilled(
        auction?.patient?.care_needs.dialysis.peritoneal_dialysis,
      ) || stringFilled(auction?.patient?.care_needs.dialysis.hemodialysis)) ===
        true,
  },
  psychiatric_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.psychiatric_care,
    exists: (auction) => !!auction?.patient?.care_needs?.psychiatric_care,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs &&
      descriptionCategory(auction?.patient?.care_needs.psychiatric_care),
  },
  Wounds: {
    valueGetter: (auction) => auction?.patient?.care_needs?.wound_care,
    exists: (auction) => !!auction?.patient?.care_needs?.wound_care,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs &&
      descriptionCategory(auction?.patient?.care_needs.wound_care),
  },
  medication: {
    valueGetter: (auction) => auction?.patient?.care_needs?.medication,
    exists: (auction) => !!auction?.patient?.care_needs?.medication,
    notEmpty: (auction) =>
      (auction?.patient?.care_needs &&
        descriptionCategory(auction?.patient?.care_needs.medication)) ||
      auction?.patient?.care_needs?.medication?.blood_thinner !== null,
  },

  compression: {
    valueGetter: (auction) => auction?.patient?.care_needs?.compression,
    exists: (auction) => !!auction?.patient?.care_needs?.compression,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs &&
      descriptionCategory(auction?.patient?.care_needs.compression),
  },
  injection: {
    valueGetter: (auction) => auction?.patient?.care_needs?.injection,
    exists: (auction) => !!auction?.patient?.care_needs?.injection,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs &&
      descriptionCategory(auction?.patient?.care_needs.injection),
  },
  access_drainages: {
    valueGetter: (auction) => auction?.patient?.care_needs?.access_drainages,
    exists: (auction) => !!auction?.patient?.care_needs?.access_drainages,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs?.access_drainages &&
      (auction?.patient?.care_needs.access_drainages.tracheal_cannula != null ||
        auction?.patient?.care_needs.access_drainages.gastric_tube != null ||
        auction?.patient?.care_needs.access_drainages.central_venous_catheter !=
          null ||
        auction?.patient?.care_needs.access_drainages
          .peripheral_venous_catheter != null ||
        auction?.patient?.care_needs.access_drainages.suprapubic_catheter !=
          null ||
        auction?.patient?.care_needs.access_drainages.permanent_catheter !=
          null ||
        auction?.patient?.care_needs.access_drainages.drainage != null ||
        auction?.patient?.care_needs.access_drainages.intravenous_injection !=
          null ||
        auction?.patient?.care_needs.access_drainages.other_access_drainages !=
          null),
  },
  base_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.base_care,
    exists: (auction) => !!auction?.patient?.care_needs?.base_care,
    notEmpty: (auction) =>
      (auction?.patient?.care_needs?.base_care &&
        (stringFilled(auction?.patient?.care_needs.base_care.description) ||
          auction?.patient?.care_needs.base_care?.companionship_grant)) ===
      true,
  },

  important_information: {
    valueGetter: (auction) =>
      auction?.patient?.care_needs?.important_information,
    exists: (auction) => !!auction?.patient?.care_needs?.important_information,
    notEmpty: (auction) =>
      stringFilled(auction?.patient?.care_needs?.important_information) ===
      true,
  },

  basic_medical_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.basic_medical_care,
    exists: (auction) => !!auction?.patient?.care_needs?.basic_medical_care,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs?.basic_medical_care?.description,
  },

  static_care_disabled_patients: {
    valueGetter: (auction) =>
      auction?.patient?.care_needs?.static_care_disabled_patients,
    exists: (auction) =>
      !!auction?.patient?.care_needs?.static_care_disabled_patients,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs?.static_care_disabled_patients
        ?.description,
  },

  companionship: {
    valueGetter: (auction) => auction?.patient?.care_needs?.companionship,
    exists: (auction) => !!auction?.patient?.care_needs?.companionship,
    notEmpty: (auction) =>
      (auction?.patient?.care_needs?.companionship &&
        (stringFilled(auction?.patient?.care_needs.companionship.description) ||
          auction?.patient?.care_needs.companionship?.companionship_grant)) ===
      true,
  },
  help_at_home: {
    valueGetter: (auction) => auction?.patient?.care_needs?.help_at_home,
    exists: (auction) => !!auction?.patient?.care_needs?.help_at_home,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs &&
      descriptionCategory(auction?.patient?.care_needs.help_at_home),
  },
  remarks: {
    valueGetter: (auction) => auction?.patient?.care_needs?.remarks,
    exists: (auction) =>
      auction?.patient?.care_needs?.remarks != null &&
      auction?.patient?.care_needs.remarks != "",
    notEmpty: (auction) =>
      auction?.patient?.care_needs?.remarks != null &&
      auction?.patient?.care_needs.remarks != "",
  },
  support: {
    valueGetter: (auction) => auction?.patient?.care_needs?.support,
    exists: (auction) => auction?.patient?.care_needs?.support != null,
    notEmpty: (auction) =>
      auction?.patient?.care_needs?.support != null &&
      !!(
        auction?.patient?.care_needs.support.eating ||
        auction?.patient?.care_needs.support.body_care ||
        auction?.patient?.care_needs.support.dressing
      ),
  },
  monitoring: {
    valueGetter: (auction) => auction?.patient?.care_needs?.monitoring,
    exists: (auction) => auction?.patient?.care_needs?.monitoring != null,
    notEmpty: (auction) =>
      !!auction?.patient?.care_needs &&
      ((auction?.patient?.care_needs.monitoring != null &&
        stringFilled(auction?.patient?.care_needs.monitoring.circulation)) ||
        (auction?.patient?.care_needs.monitoring != null &&
          stringFilled(auction?.patient?.care_needs.monitoring.respiration)) ||
        (auction?.patient?.care_needs.monitoring != null &&
          stringFilled(auction?.patient?.care_needs.monitoring.metabolism))),
  },
};

const careneedsComponentMapping: ComponentMappingItem[] = [
  {
    Component: Support,
    key: "support",
  },
  {
    Component: PalliativeCare,
    key: "palliative_care",
  },
  {
    Component: PsychiatricCare,
    key: "psychiatric_care",
  },
  {
    Component: Nutrition,
    key: "nutrition",
  },
  {
    Component: Incontinence,
    key: "incontinence",
  },
  {
    Component: Breathing,
    key: "breathing",
  },
  {
    Component: Dialysis,
    key: "Dialysis",
  },
  {
    Component: Wounds,
    key: "Wounds",
  },
  {
    Component: Medication,
    key: "medication",
  },
  {
    Component: Monitoring,
    key: "monitoring",
  },
  {
    Component: Compression,
    key: "compression",
  },
  {
    Component: Injection,
    key: "injection",
  },
  {
    Component: AccessDrainages,
    key: "access_drainages",
  },
  {
    Component: ImportantInformation,
    key: "important_information",
  },

  {
    Component: BaseCare,
    key: "base_care",
  },
  {
    Component: BasicMedicalCare,
    key: "basic_medical_care",
  },
  {
    Component: StaticCareForDisabledPatients,
    key: "static_care_disabled_patients",
  },
  {
    Component: Companionship,
    key: "companionship",
  },
  {
    Component: HelpAtHome,
    key: "help_at_home",
  },
  {
    Component: Remarks,
    key: "remarks",
  },
];

export function CareNeedsCard({
  auction,
  oldAuction,
}: {
  auction: Auction;
  oldAuction?: Auction;
}) {
  const getOntology = useGetOntology();
  const { isSenderApp } = useEnvContext();
  const translations = useTranslations();
  const { Card } = usePatientInfoContext();
  const withDiff = oldAuction != null;

  if (
    withDiff
      ? !auction.patient.care_needs && !oldAuction?.patient.care_needs
      : !auction.patient.care_needs
  )
    return null;

  const categories = getCategories({
    auction,
    componentMapping: careneedsComponentMapping,
    filters: careneedsFilters,
    forClinic: isSenderApp,
    oldAuction,
  });
  if (!exists(categories)) return null;

  return (
    <PatientInfoSlugContext.Provider
      value={{
        patientId: auction.patient.id,
        auctionId: auction.id,
        assessmentSlug: ASSESSMENT_SLUG_NEEDS,
      }}
    >
      <Card
        title={
          auction.search_type === SEARCH_TYPE_CARE
            ? translations.patient.careNeeds.stepperTitleCare
            : translations.patient.careNeeds.stepperTitle
        }
      >
        <Categories
          getOntology={getOntology}
          translations={translations}
          categories={categories}
          auction={auction}
          oldAuction={oldAuction}
        />
      </Card>
    </PatientInfoSlugContext.Provider>
  );
}
