import { useIsSealdOnly } from "containers/SealdOnlyProvider";
import { getPatientSessionKey } from "core/model/patients/encryption";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Auction } from "core/types";
import { Card } from "ds_legacy/components/InfoCard/index";
import { Divider, ResponsiveLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { Subheading, Title } from "ds_legacy/materials/typography";
import {
  BooleanField,
  CategoryContainer,
  EncryptedField,
  OntologyField,
  StringField,
} from "dsl/organisms/PatientProfileDetailsShared/shared";
import { useLoggedInAccount } from "reduxentities/hooks";
import { useTranslations } from "translations";

function TransportOrganizedByInfo({
  title,
  transportOrganizer,
}: {
  title: string;
  transportOrganizer: string;
}) {
  return (
    <StringField
      prefix={title}
      value={transportOrganizer}
      testId="transport_organiser"
    />
  );
}

function AccompanyingPersonInfo({ auction }: { auction: Auction }) {
  const translations = useTranslations();
  const account = useLoggedInAccount();
  const isSealdOnly = useIsSealdOnly().isSealdOnly({
    oldSession: auction.patient?.session_key_context?.has_session_keys,
    newSealdSession: auction.patient?.seald_encryption_context?.seald_id,
  });
  const withEncryptionAccess = isSealdOnly
    ? auction.patient?.seald_encryption_context != null
    : getPatientSessionKey(account, auction.patient) != null;

  const data = auction.patient.transport;
  if (!data) return null;

  const { accompanying_person, require_accompanying_person } = data;
  if (!require_accompanying_person) return null;

  return (
    <EncryptedField
      testId="accompanying_person"
      value={accompanying_person}
      prefix={translations.patient.transport.accompanyingPersonNecessary}
      isSealdOnly={isSealdOnly}
      withEncryptionAccess={withEncryptionAccess}
      longContext
    />
  );
}

export function TransportInfoCard({
  auction,
  forCareprovider = false,
}: {
  auction: Auction;
  forCareprovider?: boolean;
}) {
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const data = auction.patient.transport;

  if (!data) return null;

  const {
    daily_transport_needed,
    require_accompanying_person,
    transport_organiser,
    transport_type,
  } = data;

  if (
    !transport_type &&
    !transport_organiser &&
    !daily_transport_needed &&
    !require_accompanying_person
  ) {
    return null;
  }

  if (forCareprovider) {
    return (
      <>
        <Divider />
        <Subheading bold>
          {translations.patient.transport.typeOfTransport}
        </Subheading>
        <ResponsiveLayout>
          <CategoryContainer>
            <OntologyField
              getOntology={getOntology}
              type="transportType"
              value={transport_type}
              verticalLayout
            />
          </CategoryContainer>
          <CategoryContainer>
            <BooleanField
              value={daily_transport_needed}
              label={translations.patient.transport.dailyTransportNeeded}
            />
          </CategoryContainer>
        </ResponsiveLayout>
        <ResponsiveLayout>
          <CategoryContainer>
            <AccompanyingPersonInfo auction={auction} />
          </CategoryContainer>
          <CategoryContainer>
            {transport_organiser && (
              <TransportOrganizedByInfo
                transportOrganizer={transport_organiser}
                title={translations.patient.transport.organizedBy.title}
              />
            )}
          </CategoryContainer>
        </ResponsiveLayout>
      </>
    );
  }

  return (
    <Card noBorder width="100%">
      <Title>{translations.patient.transport.stepTitle}</Title>
      <OntologyField
        getOntology={getOntology}
        type="transportType"
        value={transport_type}
        verticalLayout
      />
      <BooleanField
        value={daily_transport_needed}
        label={translations.patient.transport.dailyTransportNeeded}
      />
      <AccompanyingPersonInfo auction={auction} />
      {transport_organiser && (
        <TransportOrganizedByInfo
          transportOrganizer={transport_organiser}
          title={translations.patient.transport.organizedBy.title}
        />
      )}
      <Divider margin={margin(2, 0, 0, 2)} />
    </Card>
  );
}
