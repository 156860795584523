import { useDateLocale } from "core/hooks/useDateLocale";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Auction, AuctionRequest } from "core/types";
import { usePatientEncryption } from "dsl/atoms/Contexts";
import { usePrintWindow } from "dsl/atoms/PrintButton";
import { useExtractedPatientFields } from "dsl/organisms/PatientMenu";
import { StaticPatientProfile } from "dsl/static/StaticPatientProfile";
import { useLocale, useTranslations } from "translations";

export function PatientProfilePrint({
  auction,
  auctionRequest,
  faxPrint,
  forClinic,
}: {
  auction: Auction;
  auctionRequest?: AuctionRequest;
  faxPrint?: boolean;
  forClinic?: boolean;
}) {
  usePrintWindow();
  const { currentLocale } = useDateLocale();
  const { encryptionAvailable } = usePatientEncryption();
  const extractedPatientFields = useExtractedPatientFields(auction);
  const getOntology = useGetOntology();
  const locale = useLocale();
  const translations = useTranslations();

  return (
    <StaticPatientProfile
      auction={auction}
      auctionRequest={auctionRequest}
      currentLocale={currentLocale}
      encryptionAvailable={encryptionAvailable}
      extractedPatientFields={extractedPatientFields}
      faxPrint={faxPrint}
      forClinic={forClinic}
      getOntology={getOntology}
      locale={locale}
      translations={translations}
    />
  );
}
