import { useGetAccount, useGetCachedProviderQuery } from "apollo/hooks/queries";
import { Result } from "apollo/utils";
import { CareproviderAccountProvider } from "context/CareproviderAccountContext";
import { ReactNode } from "react";
import {
  useLoggedCareproviderId,
  useLoggedInAccountId,
  useSelectToken,
  useSelectTokenType,
} from "reduxentities/hooks";

const useTokenState = () => {
  const token = useSelectToken();
  const tokenType = useSelectTokenType();
  const accountId = useLoggedInAccountId();

  return {
    accountId,
    isConnected: !!token && tokenType === "jwt" && !!accountId,
    queryToken: tokenType === "query" ? token : undefined,
  };
};

export default function CareproviderAccountsProvider({
  children,
}: {
  children: ReactNode;
}) {
  const careproviderId = useLoggedCareproviderId();
  const { accountId, isConnected, queryToken } = useTokenState();
  const [accountProgress, account] = useGetAccount(accountId ?? -1);
  const [_, careprovider] = useGetCachedProviderQuery({
    careproviderId,
    token: queryToken,
  });

  return (
    <Result
      data={account}
      queryProgress={accountProgress}
      id="careprovider-account"
    >
      {(account) => (
        <CareproviderAccountProvider
          value={{
            connected: isConnected,
            hasAccounts: !!careprovider?.accounts?.length,
            accounts: careprovider?.accounts,
            loggedAccount: account,
          }}
        >
          {children}
        </CareproviderAccountProvider>
      )}
    </Result>
  );
}
