import {
  FormControl,
  FormControlProps,
  FormHelperTextProps,
  FormLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { getAriaDescribedByValue } from "core/model/utils/strings";
import { Chip, ChipProps } from "ds_legacy/components/Chip";
import { dp, margin, sizing } from "ds_legacy/materials/metrics";
import { getFocusVisibleOutline } from "ds_legacy/materials/theme";
import { FONT_SIZE_16 } from "ds_legacy/materials/typography";
import { uniqueId } from "lodash";
import { CSSProperties, ReactNode, useState } from "react";
import {
  FieldValidation,
  FormElement,
  FormElementProps,
  isValid,
} from "react-forms-state";
import { ChipOption, getOrderedItems } from "../ChipGroup";
import { SubheadingFormLabel } from "../FormComponents/SubheadingFormLabel";
import { Validation } from "../Validation";

export type ConnectedRadioChipGroupProps = RadioChipGroupProps &
  FormElementProps;

export type RadioChipGroupProps = {
  LabelInfo?: ReactNode;
  ariaDescribedBy?: string;
  chipStyle?: {
    height?: CSSProperties["height"];
    justifyContent?: CSSProperties["justifyContent"];
    width?: CSSProperties["width"];
  };
  className?: string;
  disabled?: boolean;
  elementName: string;
  formControlSx?: FormControlProps["sx"];
  formHelperTextSx?: FormHelperTextProps["sx"];
  formLabelSx?: FormLabelProps["sx"];
  id: string;
  label: string;
  labelWrapperStyle?: CSSProperties;
  onChange: (value: number) => void;
  options: Array<RadioChipOption>;
  radioGroupSx?: RadioGroupProps["sx"];
  required?: boolean;
  sortOrder?: AnyObject;
  squared?: boolean;
  validation?: FieldValidation;
  value: Array<number> | number | undefined;
};

export type RadioChipOption = {
  disabled?: boolean;
  name: string;
  value: ToType;
};

function getId(elementName: string, name: string) {
  return `chip-${elementName}-${name
    .replace(/[ :]/g, "")
    .replace("<", "below")}`;
}

const HoverRadio = ({
  ariaDescribedBy,
  chipProps,
  elementName,
  errorTextId,
  id,
  onChange,
  option,
  required,
  validation,
}: {
  ariaDescribedBy?: string;
  chipProps: ChipProps;
  elementName: RadioChipGroupProps["elementName"];
  errorTextId: string;
  id: string;
  onChange: RadioChipGroupProps["onChange"];
  option: ChipOption;
  required: RadioChipGroupProps["required"];
  validation: RadioChipGroupProps["validation"];
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Radio
      checked={chipProps.selected}
      checkedIcon={
        <Chip {...chipProps} hovered={hovered}>
          {option.name}
        </Chip>
      }
      disabled={chipProps.disabled}
      disableTouchRipple
      focusRipple
      icon={
        <Chip {...chipProps} hovered={hovered}>
          {option.name}
        </Chip>
      }
      id={id}
      inputProps={{
        ...{ ["data-testid"]: id },
        "aria-describedby": getAriaDescribedByValue([
          !isValid(validation) && errorTextId,
          ariaDescribedBy,
        ]),
      }}
      name={elementName}
      onChange={() => {
        onChange(option.value);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      required={required}
      sx={{
        margin: "0",
        padding: "0",
        // required for accessiblity to ensure focus outline is visible
        outlineOffset: dp(1),
        ":hover": { backgroundColor: "transparent" },
        ...getFocusVisibleOutline(),
        borderRadius: 1,
        width: chipProps?.width,
      }}
      value={option.value}
    />
  );
};

export function RadioChipGroup({
  ariaDescribedBy,
  chipStyle,
  disabled,
  elementName,
  formControlSx,
  formHelperTextSx,
  formLabelSx,
  id,
  label,
  LabelInfo,
  labelWrapperStyle,
  onChange,
  options,
  radioGroupSx,
  required,
  sortOrder,
  squared,
  validation,
  value,
}: RadioChipGroupProps) {
  options = getOrderedItems(sortOrder, options);
  const errorTextId = `${elementName}-error-text`;
  const hasError = isValid(validation) === false;
  const uniqueLabelId = uniqueId(`${id}-label`);

  if (!label?.length || !id?.length) {
    console.error(
      "[RadioChipGroup] Error: Component lacks 'id' or 'label' for accessibility.",
    );
  }

  return (
    <FormControl
      component="fieldset"
      error={!isValid(validation)}
      sx={{ ...formControlSx }}
    >
      <div style={labelWrapperStyle}>
        <SubheadingFormLabel
          id={uniqueLabelId}
          error={hasError}
          required={required}
          sx={{
            fontSize: FONT_SIZE_16,
            ...formLabelSx,
          }}
        >
          {label}
        </SubheadingFormLabel>
        {LabelInfo}
      </div>
      <RadioGroup
        aria-labelledby={`${uniqueLabelId} ${
          !isValid(validation) ? errorTextId : ""
        } ${ariaDescribedBy ?? ""}`.trim()}
        row
        sx={{
          margin: margin(1, 0, 0, 2),
          gap: squared ? sizing(1) : sizing(1.5),
          ...radioGroupSx,
        }}
        value={value}
        id={id}
      >
        {options.map((option) => {
          const isDisabled = option.disabled || disabled;
          const isSelected = option.value === value;
          const id = getId(elementName, option.name);

          const chipProps: ChipProps = {
            bold: isSelected,
            className: !isDisabled && isSelected ? "checked" : undefined,
            disabled: isDisabled,
            error: hasError,
            opacity: isDisabled && isSelected ? 0.5 : undefined,
            primary: isSelected,
            selected: isSelected,
            squared,
            ...chipStyle,
          };

          return (
            <label key={`chip-${option.name}`} htmlFor={id}>
              <HoverRadio
                chipProps={chipProps}
                elementName={elementName}
                ariaDescribedBy={ariaDescribedBy}
                errorTextId={errorTextId}
                id={id}
                onChange={onChange}
                option={option}
                validation={validation}
                required={required}
              />
            </label>
          );
        })}
      </RadioGroup>
      <Validation
        id={errorTextId}
        hasCustomValidation
        validation={validation}
        elementName={elementName}
        formHelperTextSx={formHelperTextSx}
      />
    </FormControl>
  );
}

const ConnectedRadioChipGroup =
  FormElement()<ConnectedRadioChipGroupProps>(RadioChipGroup);

export default ConnectedRadioChipGroup;
