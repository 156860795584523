import {
  AHB_WITHDRAW_REASON,
  FILE_TABLE_ACTION_SYNC_VERSION,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { onPremHttpRequest } from "core/model/careseekers";
import {
  getFileMimeType,
  processDocumentFile,
  validateFile,
} from "core/model/files";
import {
  AHBMessageResponse,
  AHBMessageThread,
  Auction,
  Base64EncodedFile,
  FileHIS,
  FileHISStateful,
  Filev2Stateful,
  Patient,
  QueryProgress,
  QueryProgressActive,
  RehabForms,
  ValueOf,
} from "core/types";
import {
  GeneralListAction,
  SetAction,
} from "ds_legacy/components/Tables/GeneralTable/MenuActions";
import { useHL7ImportStatusContext } from "dsl/atoms/HL7ImportStatusContext";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useDeleteFile } from "dsl/ecosystems/PatientFiles/useDeleteFile";
import { FileFeatureData } from "dsl/ecosystems/PatientFiles/useFilesFeature";
import { useUploadFile } from "dsl/ecosystems/PatientFiles/useUploadFile";
import { useCallback, useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import { useLoggedCareseeker, useLoggedInAccountId } from "reduxentities/hooks";
import { useTranslations } from "translations";
import { createGKVRehaXml } from "./utils";

export type AHBRequestProps = {
  auction: Auction;
  patient: Patient;
};

type AHBWithdrawRequestContent = {
  Begruendung: {
    Erlaeuterung?: string; // Explanation or description of the reason (free text)
    Grund: ValueOf<typeof AHB_WITHDRAW_REASON>; // Reason for cancellation
  };
  // Datei_ID: string;
};

export type AHBSendPartialPayload = {
  forms: RehabForms;
  receiver_kim: string;
};

type AHBRequestBody = {
  account_id: number;
  auction_id: number;
  careseeker_id: number;
  content: Base64EncodedFile;
  external_id: string;
  insurance_id: number;
  patient_id: number;
  receiver_kim: string;
  sender_kim: string;
};

export type AHBWithdrawPartialPayload = {
  content: AHBWithdrawRequestContent;
  message_id: string;
  receiver_kim: string;
  sender_kim: string;
};

type AHBWithdrawRequestBody = {
  account_id: number;
  auction_id: number;
  careseeker_id: number;
  content: AHBWithdrawRequestContent;
  external_id: string;
  insurance_id: number;
  message_id: string;
  other_reason?: string;
  patient_id: number;
  receiver_kim: string;
  sender_kim: string;
};

// #TODO: could BE return an empty array instead?
const NULL_MESSAGE = "could not find any documents";

async function checkResponse(response: Response, context: string) {
  if (!response.ok) {
    let errorMessage = `[${context}]: Request failed with status ${response.status}`;
    try {
      const errorBody = await response.json();
      if (errorBody?.error) {
        errorMessage += ` - ${errorBody.error}`;
      }
    } catch (e) {
      console.warn(`[${context}]: Unable to parse error response as JSON`);
    }
    throw new Error(errorMessage);
  }
}

function extractFilenameFromHeader(response: Response) {
  const filenamePattern = /filename=([^;]+)/;
  const contentDisposition = response.headers.get("Content-Disposition");
  const match = contentDisposition?.match(filenamePattern);
  const fileName = match?.[1].trim();
  if (!fileName) {
    throw new Error("[extractFilenameFromHeader] filename not found in header");
  }
  return fileName;
}

export function useImportFilesHIS({
  dispatchOnMount,
  externalId,
  fromArchive,
}: {
  dispatchOnMount?: boolean;
  externalId: string;
  fromArchive?: boolean;
}) {
  const queryParams = `from_archive=${Boolean(fromArchive)}`;
  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [files, setFiles] = useState<FileHIS[]>();
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();

  async function fetchFiles() {
    setQueryProgress(QUERY_PROGRESS_PENDING);
    try {
      const response = await onPremHttpRequest({
        on_premise_domain_ssl,
        path: `patients/${externalId}/documents?${queryParams}`,
        method: "GET",
        on_premise_authorization_token,
      });
      await checkResponse(response, "useImportFilesHIS");
      const payload = await response.json();
      const isEmpty = !payload || payload === NULL_MESSAGE;
      setFiles(isEmpty ? [] : payload);
      setQueryProgress(QUERY_PROGRESS_SUCCEED);
    } catch (err) {
      console.error("[useImportFilesHIS]:", (err as Error).message);
      setQueryProgress(QUERY_PROGRESS_FAILED);
    }
  }

  useEffect(() => {
    if (dispatchOnMount) {
      fetchFiles();
    }
  }, []);

  return { queryProgress, files, fetchFiles };
}

export function useImportAndUploadFilesContentHIS({
  action,
  externalId,
  filesHIS,
  handleDeleteFile,
  handleUploadFile,
  setAction,
  skip,
}: {
  action: GeneralListAction<FileFeatureData | undefined> | undefined;
  externalId: string;
  filesHIS: FileHISStateful[] | undefined;
  handleDeleteFile: ReturnType<typeof useDeleteFile>["handleDeleteFile"];
  handleUploadFile: ReturnType<typeof useUploadFile>["handleUploadFile"];
  setAction: SetAction<FileFeatureData>;
  skip: boolean;
}) {
  const toast = useToast();
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();

  async function fetchFilesDetails({
    filesHIS,
  }: {
    filesHIS: (Pick<
      FileHISStateful,
      | "document_category_number"
      | "document_category_string"
      | "document_id"
      | "id"
      | "share_mode"
    > & { fileV2Id?: Filev2Stateful["id"] })[];
  }) {
    try {
      await Promise.all(
        filesHIS.map(
          async ({
            document_category_number,
            document_category_string,
            document_id,
            fileV2Id,
            id,
            share_mode,
          }) => {
            const response = await onPremHttpRequest({
              on_premise_domain_ssl,
              path: `patients/${externalId}/documents/${document_id}`,
              method: "GET",
              on_premise_authorization_token,
            });
            await checkResponse(response, "useImportAndUploadFilesContentHIS");

            const document_file_name = extractFilenameFromHeader(response);
            const blob = await response.blob();
            const type = getFileMimeType({ filename: document_file_name });
            const file = new File([blob], document_file_name, { type });

            await validateFile({
              file,
              fromArchive: true,
              trackEvent,
              translations,
            });
            const content = await processDocumentFile(file);
            await handleUploadFile({
              category: document_category_number,
              category_other: document_category_string,
              content,
              file,
              fileName: document_file_name,
              kis_document_id_string: id,
              share_mode,
            });
            if (action?.type === FILE_TABLE_ACTION_SYNC_VERSION && fileV2Id) {
              await handleDeleteFile(fileV2Id);
            }
          },
        ),
      );
    } catch (err) {
      console.error("Unexpected import error:", (err as Error).message);
      toast({
        color: "danger",
        message: translations.fileUploader.fileCouldNotFetch,
      });
    } finally {
      setAction(undefined);
    }
  }

  useEffect(() => {
    if (!skip && filesHIS?.length) {
      fetchFilesDetails({ filesHIS });
    }
  }, [skip, filesHIS]);

  return;
}

export function useGetAHBMessageThreads({
  dispatchOnMount,
  externalId,
}: {
  dispatchOnMount?: boolean;
  externalId: string | undefined;
}) {
  const [progress, setProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [ahbMessages, setAHBMessages] = useState<AHBMessageThread[]>();
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();

  const getAHBMessageThreads = async () => {
    setProgress(QUERY_PROGRESS_PENDING);
    try {
      if (!externalId) {
        throw new Error("externalId is required");
      }
      const response = await onPremHttpRequest({
        method: "GET",
        on_premise_authorization_token,
        on_premise_domain_ssl,
        path: `patients/${externalId}/messages`,
      });
      await checkResponse(response, "useGetAHBMessageThreads");

      const text = await response.text();
      const payload = text ? JSON.parse(text) : [];
      setAHBMessages(payload ?? []);
      setProgress(QUERY_PROGRESS_SUCCEED);
    } catch (err) {
      console.error("[useGetAHBMessageThreads]:", (err as Error).message);
      setProgress(QUERY_PROGRESS_FAILED);
    }
  };

  useEffect(() => {
    if (dispatchOnMount) {
      getAHBMessageThreads();
    }
  }, [dispatchOnMount, externalId]);

  return [progress, ahbMessages, getAHBMessageThreads] as const;
}

export function useGetAHBResponse() {
  const [progress, setProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [ahbResponse, setAHBResponse] = useState<Base64EncodedFile>();

  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();

  const getAHBResponse = useCallback(
    async (response_id: AHBMessageResponse["response_id"]) => {
      setProgress(QUERY_PROGRESS_PENDING);
      try {
        if (!response_id) {
          throw new Error("response_id is required");
        }

        const response = await onPremHttpRequest({
          method: "GET",
          on_premise_authorization_token,
          on_premise_domain_ssl,
          path: `responses/${response_id}/content`,
        });

        await checkResponse(response, "useGetAHBResponse");

        const payload: Base64EncodedFile =
          (await response.text()) as Base64EncodedFile;
        setProgress(QUERY_PROGRESS_SUCCEED);
        setAHBResponse(payload);

        return payload;
      } catch (err) {
        console.error("[useGetAHBResponse]:", (err as Error).message);
        setProgress(QUERY_PROGRESS_FAILED);
      }
    },
    [on_premise_authorization_token, on_premise_domain_ssl],
  );

  return [progress, getAHBResponse, ahbResponse] as const;
}

export function useSendAHBRequest({ auction, patient }: AHBRequestProps) {
  const toast = useToast();
  const translations = useTranslations();
  const loggedCareseeker = useLoggedCareseeker();
  const accountId = useLoggedInAccountId();
  const careseeker = useLoggedCareseeker();
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    loggedCareseeker?.config ?? {};
  const [progress, setProgress] = useState<QueryProgressActive>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const insurance_id = patient.profile?.financing?.insurance?.id;

  const sendAHBRequest = async ({
    forms,
    receiver_kim,
  }: AHBSendPartialPayload) => {
    setProgress(QUERY_PROGRESS_PENDING);
    try {
      if (!patient.external_id) {
        throw new Error("external_id is required");
      }
      if (!loggedCareseeker?.kim_address) {
        throw new Error("sender_kim address is required");
      }
      if (!receiver_kim) {
        throw new Error("receiver_kim address is required");
      }
      if (!insurance_id) {
        throw new Error("insurance_id is required");
      }

      const xml = await createGKVRehaXml({ forms, shouldValidate: true });

      /** ------------- For Testing Purposes --------------- */
      // const xslUrl = getStaticAsset("Stylesheet_GKHAR_1.1.0.xsl");
      // const res = await fetch(xslUrl);
      // if (!res.ok) throw new Error("Failed to load XSL");
      // const xslText = await res.text();

      // const pdfBytes = await transformXmlToPdf({ xmlText: xml, xslText });
      // openPdfInNewWindow(pdfBytes);
      // return

      /** ------------------------------------------------- */

      const body: AHBRequestBody = {
        account_id: accountId ?? -1,
        auction_id: auction.id,
        careseeker_id: careseeker?.id ?? -1,
        content: btoa(xml) as Base64EncodedFile,
        external_id: patient.external_id,
        insurance_id,
        patient_id: patient.id,
        receiver_kim,
        sender_kim: loggedCareseeker?.kim_address,
      };

      const response = await onPremHttpRequest({
        on_premise_domain_ssl,
        path: `patients/${patient.external_id}/ahb_request`,
        method: "POST",
        on_premise_authorization_token,
        body: JSON.stringify(body),
      });
      await checkResponse(response, "useSendAHBRequest");

      toast({
        color: "success",
        message:
          translations.insuranceApp.eligibilityTab.sendDocuments.successMessage,
      });
    } catch (err) {
      console.error("[useSendAHBRequest]:", (err as Error).message);
      toast({
        color: "danger",
        message:
          translations.insuranceApp.eligibilityTab.sendDocuments.errorMessage,
      });
    } finally {
      setProgress(QUERY_PROGRESS_NOT_STARTED);
    }
  };

  return [progress, sendAHBRequest] as const;
}

export function useWithdrawAHBRequest({ auction, patient }: AHBRequestProps) {
  const toast = useToast();
  const translations = useTranslations();
  const loggedCareseeker = useLoggedCareseeker();
  const accountId = useLoggedInAccountId();
  const careseeker = useLoggedCareseeker();
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    loggedCareseeker?.config ?? {};
  const [progress, setProgress] = useState<QueryProgressActive>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const insurance_id = patient.profile?.financing?.insurance?.id;

  const withdrawAHBRequest = async (
    partialPayload: AHBWithdrawPartialPayload,
  ) => {
    setProgress(QUERY_PROGRESS_PENDING);
    try {
      if (!patient.external_id) {
        throw new Error("external_id is required");
      }
      if (!partialPayload.receiver_kim) {
        throw new Error("receiver_kim address is required");
      }
      if (!insurance_id) {
        throw new Error("insurance_id is required");
      }

      const body: AHBWithdrawRequestBody = {
        ...partialPayload,
        account_id: accountId ?? -1,
        auction_id: auction.id,
        careseeker_id: careseeker?.id ?? -1,
        external_id: patient.external_id,
        insurance_id: insurance_id,
        patient_id: patient.id,
      };

      const response = await onPremHttpRequest({
        body: JSON.stringify(body),
        method: "POST",
        on_premise_authorization_token,
        on_premise_domain_ssl,
        path: `patients/${patient.external_id}/messages/${encodeURIComponent(
          partialPayload.message_id,
        )}/withdraw`,
      });
      await checkResponse(response, "useWithdrawAHBRequest");

      toast({
        color: "success",
        message:
          translations.insuranceApp.eligibilityTab.sendDocuments.withdrawModal
            .withdrawSuccess,
      });
    } catch (err) {
      console.error("[useWithdrawAHBRequest]:", (err as Error).message);
      toast({
        color: "danger",
        message:
          translations.insuranceApp.eligibilityTab.sendDocuments.withdrawModal
            .withdrawError,
      });
    } finally {
      setProgress(QUERY_PROGRESS_NOT_STARTED);
    }
  };

  return [progress, withdrawAHBRequest] as const;
}
