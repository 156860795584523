import { Paper, Table } from "@mui/material";
import { COLUMN_TYPE_FILES_CATEGORY } from "core/consts";
import { GREY_600, TEXT_SUBTITLE } from "ds_legacy/materials/colors";
import {
  GENERAL_TABLE_TOP_MARGIN,
  border,
  dp,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_10,
  FONT_SIZE_14,
  FONT_SIZE_22,
  FONT_WEIGHT_BOLD,
  Heading,
} from "ds_legacy/materials/typography";
import styled, { CSSProperties } from "styled-components";
import {
  AllColumnKinds,
  COLUMN_TYPE_DEFAULT_ACTION,
  COLUMN_TYPE_DEFAULT_DATE,
} from "./CustomCells";
import {
  COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS,
  COLUMN_TYPE_SEARCH_TABLE_STATUS,
} from "./CustomCells/SearchTables";

export const TableIntroTitle = styled(Heading)`
  font-size: ${FONT_SIZE_22};
  font-weight: ${FONT_WEIGHT_BOLD};
  margin: ${margin(0)};

  @media print {
    break-after: avoid;
  }
`;

export const TableIntroSubtitle = styled(Body)<{ icon?: boolean }>`
  max-width: 60%;
  font-size: ${FONT_SIZE_14};
  color: ${TEXT_SUBTITLE};
  margin: ${({ icon }) => (icon ? margin(0, 0, 0, 4.5) : margin(0))};
  padding: ${padding(0)};

  @media print {
    max-width: 100%;
    break-after: avoid;
    font-size: ${FONT_SIZE_10};
    padding: ${padding(0, 1)};
  }
`;

export const DefaultCellBodyText = styled(Body)<{
  padding?: CSSProperties["padding"];
  width?: CSSProperties["width"];
  wordBreak?: CSSProperties["wordBreak"];
  wordWrap?: CSSProperties["wordWrap"];
}>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: ${FONT_SIZE_14};
  margin: ${(props) => props.margin || margin(0)};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  word-wrap: break-word;

  @media print {
    width: ${(props) => props.width || undefined};
    text-overflow: clip;
    overflow: visible;
    -webkit-line-clamp: unset;
    white-space: normal;
    font-size: ${dp(8)};
    word-break: ${(props) => props.wordBreak || "normal"};
    word-wrap: ${(props) => props.wordWrap || "normal"};
    padding: ${(props) => props.padding || padding(0.5, 0)};
    break-inside: avoid;
    line-height: normal;
  }
`;

export const StyledPaper = styled(Paper)<{
  $isTablet: boolean;
}>`
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  padding: ${({ $isTablet }) =>
    $isTablet ? padding(0, 4) : padding(0, 4, 2, 4)};
  margin: ${({ $isTablet }) => ($isTablet ? 0 : `${margin(3)} auto 0`)};
  & > * {
    padding: ${padding(4, 0, 2, 0)};
  }
`;

export const StyledTablePaper = styled(Paper)`
  box-shadow: ${dp(0)} ${dp(4)} ${dp(10)} rgba(119, 85, 119, 0.2) !important;
  border-radius: ${dp(4)};
  box-sizing: border-box;
  overflow: visible;
  width: 100%;
  padding: ${padding(0, 2)};
  margin: ${dp(GENERAL_TABLE_TOP_MARGIN)} auto ${sizing(2)} ${sizing(1)};

  @media print {
    padding: ${padding(0)};
    margin: ${margin(2, 0)};
    border: ${border({
      color: GREY_600,
    })};
    box-shadow: none !important;
  }
`;

function excludeColumns(columns: AllColumnKinds[]) {
  return columns.map((column) => `:not([data-type="${column}"])`).join("");
}

export const StyledGeneralTable = styled(Table)`
  table-layout: auto;
  width: 100%;

  tr {
    height: ${dp(52)};
  }
  tr td:first-child,
  tr th:first-child {
    padding: ${padding(2, 0, 2, 1)};
  }
  tr td:last-child {
    padding: ${padding(2, 1)};
  }
  tr th:last-child {
    padding: ${padding(0)};
  }
  tr:last-child td {
    border-bottom: none;
  }
  th {
    white-space: nowrap;
    font-weight: ${FONT_WEIGHT_BOLD};
    border-bottom-color: ${GREY_600};
    padding: ${padding(2, 1)};
  }
  td {
    text-align: "left";
    vertical-align: "top";
    padding: ${padding(2, 1)};
  }
  td[data-type="${COLUMN_TYPE_DEFAULT_ACTION}"] {
    width: ${sizing(4)};
    padding: ${padding(1)}!important;
  }
  td[data-type="${COLUMN_TYPE_DEFAULT_DATE}"] {
    white-space: nowrap;
  }
  td[data-type="${COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY}"] {
    max-width: ${sizing(1)};
  }
  td${excludeColumns([
      COLUMN_TYPE_DEFAULT_ACTION,
      COLUMN_TYPE_DEFAULT_DATE,
      COLUMN_TYPE_FILES_CATEGORY,
      COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY,
      COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS,
      COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT,
      COLUMN_TYPE_SEARCH_TABLE_STATUS,
    ])} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${dp(1)};
  }

  @media print {
    table {
      break-inside: auto;
    }
    th {
      break-inside: avoid;
      font-size: ${dp(8)};
      font-weight: ${FONT_WEIGHT_BOLD};
      padding: ${padding(0)};
    }
    tr {
      border-bottom: ${border({ color: GREY_600 })};
      break-inside: avoid;
      break-after: auto;
      height: ${dp(10)};
      padding: ${padding(0, 1)};
    }

    td {
      border-bottom: ${border({ color: GREY_600 })};
      break-inside: avoid;
      break-after: auto;
      padding: ${padding(0.25)};
      text-align: left;
    }

    tr td:first-child,
    tr th:first-child {
      border-bottom: ${border({ color: GREY_600 })};
      padding: ${padding(0, 0, 0, 1)};
    }
    tr td:last-child {
      border-bottom: ${border({ color: GREY_600 })};
      break-inside: avoid;
      padding: ${padding(0)};
    }

    tr th:last-child {
      border-bottom: ${border({ color: GREY_600 })};
      padding: ${padding(0, 1, 0)};
    }

    td[data-type="${COLUMN_TYPE_DEFAULT_ACTION}"] {
      display: none;
      border-bottom: ${border({ color: GREY_600 })};
      break-inside: avoid;
      padding: ${padding(0)}!important;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
`;
