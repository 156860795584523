import { useEnvContext } from "context/EnvContext";
import {
  ASSESSMENT_SLUG_TRANSPORT_INFO,
  SEARCH_TYPE_TRANSPORT,
} from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import { formatPickUpTime } from "core/model/patients";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Auction, MonitoringNeeds } from "core/types";
import { format, fromUnixTime, isValid } from "date-fns";
import { margin, padding } from "ds_legacy/materials/metrics";
import { getPredicamentValue } from "react-forms-state";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { PatientInfoSlugContext, usePatientInfoContext } from ".";
import { TreatmentLocationCard } from "./TreatmentLocationCard";
import {
  BooleanField,
  Categories,
  Category,
  LocationContentSection,
  StringField,
} from "./shared";
import { ComponentMappingItem, Filter, getCategories } from "./utils";

const Spacer = styled.div<{ margin?: string; padding?: string }>`
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex: 1 1 auto;
  margin: ${({ margin }) => margin ?? 0};
  padding: ${({ padding }) => padding ?? 0};
`;

export const formatPickupStringValue = (
  value: Auction | null | undefined,
  currentLocale: Locale,
  suffix?: string,
) => {
  const startTime = value?.profile?.pick_up?.start;
  const endTime = value?.profile?.pick_up?.end;
  const timeFormat = "HH:mm";

  const trimmedSuffix = suffix?.trim();
  const showSuffix = trimmedSuffix ? ` ${trimmedSuffix}` : "";

  const startFormatted = format(
    formatPickUpTime(startTime) || new Date(),
    timeFormat,
    { locale: currentLocale },
  );
  const endFormatted = format(
    formatPickUpTime(endTime) || new Date(),
    timeFormat,
    { locale: currentLocale },
  );

  if (
    !isValid(formatPickUpTime(startTime)) ||
    !isValid(formatPickUpTime(endTime))
  )
    return null;

  return `${startFormatted}${showSuffix} - ${endFormatted}${showSuffix}`;
};

const getTransportMonitoringValues = ({
  translations,
  value,
}: {
  translations: Translations;
  value: MonitoringNeeds | null | undefined;
}): string => {
  if (!value) return "";

  const monitoringTranslations: string[] = [];

  if (value.ecg)
    monitoringTranslations.push(
      translations.assessments.transportSearch.monitoringHeart,
    );
  if (value.pulse_oximeter)
    monitoringTranslations.push(
      translations.assessments.transportSearch.monitoringPulse,
    );

  if (monitoringTranslations.length === 0) return "";

  return `${translations.patient.patientInformation.monitoringNeeds}${
    translations.general.colon
  } ${monitoringTranslations.join(", ")}`;
};

const transportComponentMapping: ComponentMappingItem[] = [
  {
    Component: PickUpSection,
    key: "pick_up",
  },

  {
    Component: Destination,
    key: "destination",
  },
  {
    Component: TransportEquipment,
    key: "transport_equipment",
  },
];

export const transportFilters: Record<string, Filter> = {
  pick_up: {
    exists: (auction) => auction != null,
    valueGetter: (auction) => auction,
  },
  transport_equipment: {
    exists: (auction) => auction != null,
    valueGetter: (auction) => auction,
  },
  destination: {
    exists: (auction) => auction != null,
    valueGetter: (auction) => auction,
  },
};

function PickUpSection({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: Auction | null | undefined;
  translations: Translations;
  value: Auction | null | undefined;
  withDiff: boolean;
}) {
  const { currentLocale } = useDateLocale();
  return (
    <Category
      category="pick_up"
      title={translations.auctionRequest.pickUpInformation}
    >
      {value?.start_date && (
        <StringField
          testId="pick_up_start_date"
          prefix={translations.auctionRequest.transportDate}
          value={format(fromUnixTime(value.start_date), "P", {
            locale: currentLocale,
          })}
          oldValue={format(fromUnixTime(oldValue?.start_date || 0), "P", {
            locale: currentLocale,
          })}
          withDiff={withDiff}
        />
      )}
      {formatPickupStringValue(
        value,
        currentLocale,
        translations.auctionRequest.time,
      ) != null && (
        <StringField
          testId="pick_up_time"
          prefix={translations.assessments.transportSearch.pickUpTime}
          value={formatPickupStringValue(
            value,
            currentLocale,
            translations.auctionRequest.time,
          )}
          oldValue={formatPickupStringValue(
            oldValue,
            currentLocale,
            translations.auctionRequest.time,
          )}
          withDiff={withDiff}
        />
      )}
      <LocationContentSection
        bold={false}
        location={value?.profile?.search_location}
        oldLocation={oldValue?.profile?.search_location}
        withDiff={withDiff}
        title={translations.auctionRequest.pickUpAddress}
      />
      <StringField
        testId="search_location_floor"
        prefix={translations.patient.patientInformation.floor}
        value={value?.profile?.search_location?.floor}
        oldValue={oldValue?.profile?.search_location?.floor}
        withDiff={withDiff}
      />
    </Category>
  );
}

function Destination({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: Auction | null | undefined;
  translations: Translations;
  value: Auction | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="destination"
      title={translations.auctionRequest.destination}
    >
      <LocationContentSection
        location={value?.profile?.search_destination}
        oldLocation={oldValue?.profile?.search_destination}
        withDiff={withDiff}
        bold={false}
        title={translations.address.address}
      />
      <StringField
        testId="station_full_name"
        prefix={translations.acp.stationCode}
        value={value?.patient?.station_full?.name}
        oldValue={oldValue?.patient?.station_full?.name}
        withDiff={withDiff}
      />
      <StringField
        testId="search_destination_floor"
        prefix={translations.patient.patientInformation.floor}
        value={value?.profile?.search_destination?.floor}
        oldValue={oldValue?.profile?.search_destination?.floor}
        withDiff={withDiff}
      />
      <StringField
        testId="lift_available_state"
        value={getPredicamentValue({
          label: translations.patient.patientInformation.liftAvailable,
          value: value?.patient?.profile?.lift_available_state,
          translations,
        })}
        oldValue={getPredicamentValue({
          label: translations.patient.patientInformation.liftAvailable,
          value: oldValue?.patient?.profile?.lift_available_state,
          translations,
        })}
        withDiff={withDiff}
      />
      <StringField
        testId="lift_available_description"
        value={value?.patient?.profile?.lift_available_description}
        oldValue={oldValue?.patient?.profile?.lift_available_description}
        withDiff={withDiff}
      />
    </Category>
  );
}

function TransportEquipment({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: Auction | null | undefined;
  translations: Translations;
  value: Auction | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category category="transport_equipment" title="">
      {value?.profile?.no_site_support_required && (
        <Spacer padding={padding(0.5)} />
      )}
      <BooleanField
        value={value?.profile?.no_site_support_required}
        oldValue={oldValue?.profile?.no_site_support_required}
        label={translations.assessments.transportSearch.onsiteSupportRequired}
        withDiff={withDiff}
      />
      <StringField
        testId="needs_oxygen"
        value={getPredicamentValue({
          label: translations.patient.patientInformation.needsOxygen,
          value: value?.profile?.needs_oxygen,
          translations,
        })}
        oldValue={getPredicamentValue({
          label: translations.patient.patientInformation.needsOxygen,
          value: oldValue?.profile?.needs_oxygen,
          translations,
        })}
        withDiff={withDiff}
      />
      <StringField
        testId="is_visually_impaired"
        value={getPredicamentValue({
          label: translations.patient.patientInformation.visualImpairment,
          value: value?.profile?.is_visually_impaired,
          translations,
        })}
        oldValue={getPredicamentValue({
          label: translations.patient.patientInformation.visualImpairment,
          value: value?.profile?.is_visually_impaired,
          translations,
        })}
        withDiff={withDiff}
      />
      <StringField
        testId="monitoring_needs"
        value={getTransportMonitoringValues({
          value: value?.profile?.monitoring_needs,
          translations,
        })}
        oldValue={getTransportMonitoringValues({
          value: oldValue?.profile?.monitoring_needs,
          translations,
        })}
        withDiff={withDiff}
      />
      <StringField
        testId="care_needs_remarks"
        prefix={translations.patient.additionalInformation}
        value={value?.patient?.care_needs?.remarks}
        oldValue={oldValue?.patient?.care_needs?.remarks}
        withDiff={withDiff}
      />
    </Category>
  );
}

export function TransportSearchCard({
  auction,
  oldAuction,
}: {
  auction: Auction;
  oldAuction?: Auction;
}) {
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const { isSenderApp } = useEnvContext();
  const categories = getCategories({
    auction,
    componentMapping: transportComponentMapping,
    filters: transportFilters,
    forClinic: isSenderApp,
    oldAuction,
  });
  const { Card, isClinicApp } = usePatientInfoContext();
  if (auction?.search_type !== SEARCH_TYPE_TRANSPORT) return null;

  return (
    <Card title={translations.patient.transport.stepTitle}>
      <PatientInfoSlugContext.Provider
        value={{
          patientId: auction.patient.id,
          auctionId: auction.id,
          assessmentSlug: ASSESSMENT_SLUG_TRANSPORT_INFO,
        }}
      >
        <Categories
          auction={auction}
          oldAuction={oldAuction}
          translations={translations}
          getOntology={getOntology}
          categories={categories}
        />
        <Spacer margin={margin(2)} />
        <TreatmentLocationCard
          component={`Transport profile card: ${
            isClinicApp ? "Patient Profile" : "AuctionRequest"
          }`}
          auction={auction}
          oldAuction={oldAuction}
          show
        />
      </PatientInfoSlugContext.Provider>
    </Card>
  );
}
